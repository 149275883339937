import React, {useEffect, useState} from "react";
import {
  DrawerBottomMenu,
  DrawerMainMenu,
  OrganizationChoiceButton,
  ProfileButton,
} from "src/components/dashboard/components";
import {getOrganizationsCount, getSelectedOrganization} from "src/features/dashboard";
import {useSelector} from "react-redux";
import DashboardLayout from "./dashboard-layout-view";
import {useLoggedIn} from "src/features/dashboard/dashboard-hooks";
import FeedbackForm from "src/components/dashboard/feedback-view";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {navigate} from "gatsby";
import {getProfile} from "src/features/participation";
import {isParticipationViewAvailable} from "src/features/session";

export default function DashboardLayoutRedux(
  {
    drawerContentProps,
    selectedPage,
    children,
    ...props
  },
) {
  const {
    id,
    render_name: organizationTitle,
    has_credentials: hasCredentials,
    requires_test: requiresTest,
  } = useSelector(getSelectedOrganization);

  const allowParticipationView = useSelector(isParticipationViewAvailable);

  useLoggedIn();

  useEntityObserver({type: 'organization', id});

  const {
    has_just_set_up_credentials: hasJustSetUpCredentials,
  } = useSelector(getProfile);

  useEffect(() => {
    if (hasCredentials === false && !hasJustSetUpCredentials && allowParticipationView) {
      navigate("/teilnahme/dashboard/");
    }
  }, [hasCredentials, hasJustSetUpCredentials]);

  const organizationsCount = useSelector(getOrganizationsCount);

  const [feedbackOpen, setFeedbackOpen] = useState(null);

  return (
    <DashboardLayout
      organizationTitle={organizationTitle}
      organizationRequiresTests={requiresTest}
      toolbarTitleContent={
        organizationsCount > 1 ? (
          <OrganizationChoiceButton title={organizationTitle}/>
        ) : undefined
      }
      toolbarEndContent={(
        <>
          <ProfileButton/>
        </>
      )}
      drawerContent={(
        <DrawerMainMenu
          selected={selectedPage}
          {...drawerContentProps}
        />
      )}
      drawerBottomContent={(
        <DrawerBottomMenu
          selected={!feedbackOpen ? selectedPage : null}
          {...drawerContentProps}
          openFeedback={() => setFeedbackOpen(true)}
          feedbackOpen={!!feedbackOpen}
        />
      )}
      {...props}
    >
      {feedbackOpen !== null ? (
        <FeedbackForm open={feedbackOpen} onClose={() => setFeedbackOpen(false)} disableUnloadBlocker/>
      ) : null}
      {children}
    </DashboardLayout>
  );
}

import {isLoggedIn} from "src/features/session";
import {useSelector} from "react-redux";
import {navigate} from "gatsby";
import {getRequestUserContactRole, getSelectedOrganization} from "src/features/dashboard/dashboard-slice";

export function useLoggedIn() {
  const loggedIn = useSelector(isLoggedIn);
  if (!loggedIn) {
    navigate('/');
  }
}

export function useHasPermissions(permissions) {
  const {request_user_is_superuser} = useSelector(getSelectedOrganization);
  const requestUserContactRole = useSelector(getRequestUserContactRole);

  if (request_user_is_superuser) {
    return true;
  }

  let hasPermissions = true;
  Object.entries(permissions)
    .forEach(([permission, value]) => {
      if (requestUserContactRole[permission] !== value) {
        if (hasPermissions && requestUserContactRole[permission] === undefined) {
          hasPermissions = undefined;
        } else {
          hasPermissions = false;
        }
      }
    });
  return hasPermissions;
}

export function useRequirePermissions(permissions) {
  const hasPermissions = useHasPermissions(permissions);
  if (hasPermissions === false) {
    navigate('/dashboard/');
  }
}

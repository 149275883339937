import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";
import React, {useState} from "react";
import _ from "lodash";

export default function Choices(
  {
    errors = {},
    control,
    name,
    variant = "standard",
    fullWidth = true,
    label,
    items = {},
    margin,
    onFocus,
    onBlur,
    helperText,
    ...props
  },
) {
  const error = _.get(errors, name);

  // let [open, setOpen] = useState(null);
  const [focus, setFocus] = useState(false);

  // if (open === null) {
  //   open = focus;
  // }

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      margin={margin}
      error={!!error}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        }
        return setFocus(true);
      }}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
        return setFocus(false);
      }}
      // onBlur={onBlur}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({field: {value, ref, ...otherProps}}) => (
          <Select
            label={label}
            inputProps={{
              id: name,
            }}
            {...props}
            {...otherProps}
            inputRef={ref}
            value={value === null ? 'null' : value}
            // open={open}
            // onOpen={() => setOpen(true)}
            // onClose={() => setOpen(false)}
          >
            {Object.entries(items)
              .map(([value, key]) => (
                <MenuItem value={value} key={value}>{key}</MenuItem>
              ))}
          </Select>
        )}
      />
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}

import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {};

const entitySlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setEntities(state, action) {
      const {entities, created, createdForOrganization} = action.payload;
      Object.entries(entities).forEach(([entityType, items]) => {
        // Merge in entities into potentially existing map.
        if (state[entityType] === undefined) {
          state[entityType] = {
            byId: {},
            createdIdsByOrganization: {},
            deletedIds: [],
          };
        }

        const entityState = state[entityType].byId;
        Object.entries(items).forEach(([key, value]) => {
          if (created) {
            if (!state[entityType].createdIdsByOrganization[createdForOrganization]) {
              state[entityType].createdIdsByOrganization[createdForOrganization] = [];
            }
            state[entityType].createdIdsByOrganization[createdForOrganization].push(key);
          }

          if (entityState[key] === undefined || !value) {
            entityState[key] = value;
            if (value === null && !state[entityType].deletedIds.includes(key)) {
              state[entityType].deletedIds.push(key);
            }
            return;
          }

          if (!_.isEqual(entityState[key], value)) {
            entityState[key] = {
              ...entityState[key],
              ...value,
            };
          }
        });
      });
    },
  },
});

export const {
  setEntities,
} = entitySlice.actions;

export const registerEntityObserver = createAction('entity/registerObserver');
export const unregisterEntityObserver = createAction('entity/unregisterObserver');

export const deleteEntity = (entityType, id) =>
  setEntities({entities: {[entityType]: {[id]: null}}});

const reducers = {
  entities: entitySlice.reducer,
};
export default reducers;

const getEntitiesState = (state) => state.entities;

export const getCreatedEntityIdsByOrganization = createSelector(
  getEntitiesState,
  (entities) => _.memoize((organization) => _.memoize((entityType) => (
    entities?.[entityType]?.createdIdsByOrganization[organization] || []
  ))),
);

const emptyEntity = {};
const deletedEntity = {isDeleted: true};

export const getEntity = createSelector(
  getEntitiesState,
  (entities) => _.memoize((entityType) => _.memoize((id) => {
    let entity = entities?.[entityType]?.byId?.[id];
    if (entity === null) {
      return deletedEntity;
    }
    return entity || emptyEntity;
  })),
);

export const getOrganizationGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('organizations'),
);

export const getContactGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('contacts'),
);

export const getDocumentGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('documents'),
);

export const getLabelGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('labels'),
);

export const getNotificationGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('notifications'),
);

export const getContactRoleGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('contact_roles'),
);

export const getStationGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('stations'),
);

export const getGEMAGVL4StationGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvl4_stations'),
);

export const getUploadedFileGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('uploaded_files'),
);

export const getOrgMusicWorkGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('org_music_works'),
);

export const getDatabaseGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('databases'),
);

export const getLookupTableGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('lookup_tables'),
);

export const getDatabaseRightGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('database_rights'),
);

export const getOrgMusicWorkLogsGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('org_music_work_logs'),
);

export const getOrgMusicWorkLogGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('org_music_work_log'),
);

export const getErschieneneTonaufnahmeGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('erschienene_tonaufnahmen'),
);

export const getMusikPersonGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('musik_personen'),
);

export const getMusikProduktionIdGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('musik_produktion_ids'),
);

export const getGEMAGVLXMLLieferungGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvlxml_lieferungen'),
);

export const getGEMAGVLXMLLieferungStatsGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvlxml_lieferungen_stats'),
);

export const getGEMAGVLXMLExportGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvlxml_exports'),
);

export const getGEMAGVLXMLAusstrahlungGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvlxml_ausstrahlungen'),
);

export const getGEMAGVL4LieferungGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvl4_lieferungen'),
);

export const getGEMAGVL4AusstrahlungGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gemagvl4_ausstrahlungen'),
);

export const getTodoTaskGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('todo_tasks'),
);

export const getKnownBugGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('known_bugs'),
);

export const getGVLProductGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gvl_products'),
);

export const getGEMAWorkGetter = createSelector(
  getEntity,
  (entityGetter) => entityGetter('gema_works'),
);

import {delay, put} from 'redux-saga/effects';
import {takeLatestDeepForThrottling} from "src/packages/effects";
import {endProgress, reportProgress, startProgress, updateProgress} from "./progress-slice";

/**
 * Track simple progress of an action's execution in redux.
 */
export const withProgress = (actionToProgress, rethrow=false) => saga => (function*(action, ...args) {
  const progress = actionToProgress(action);
  yield put(startProgress(progress));
  try {
    const result = yield saga(action, ...args);
    yield put(endProgress({...progress, success: true}));
    return result;
  } catch (e) {
    yield put(endProgress({...progress, error: e.message || "error", formErrors: e.formErrors}));
    if(rethrow) {
      throw e;
    }
  }
});

function* handleProgressChange({payload}) {
  yield delay(300);
  yield put(updateProgress(payload));
}

export default function* progressSaga() {
  yield takeLatestDeepForThrottling(
    reportProgress,
    action => `${action.payload.type}?${action.payload.id}`,
    300,
    handleProgressChange,
  );
}

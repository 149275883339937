import * as yup from "yup";
import yupLocaleDe from 'src/packages/yup-locale-de';

import _ from 'lodash';
import {ENCODINGS} from "src/packages/encodings";
import {TIMEZONES} from "src/packages/timezones";
import {formatISO} from "date-fns";

yup.setLocale(yupLocaleDe);

export const MUSIK_HERKUNFT_KNZ = {
  EIGEN: "Eigenproduktion",
  AUFTRAG: "Auftragsproduktion",
  LIVE: "Live",
  VTON: "veröffentlichte Tonaufnahme",
  PLM: "Production Music Library",
};

export const CONTACT_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organizations: yup.array(yup.string()),
  salutation: yup.string()
    .label("Anrede")
    .meta({
      options: {
        frau: "Frau",
        herr: "Herr",
        '': "(neutral)",
      },
      optionKeyBindings: {
        f: 'frau',
        h: 'herr',
      },
      autoComplete: 'sex',
    })
    .trim()
    .max(255)
    .nullable(),
  title: yup.string()
    .label("Titel")
    .meta({
      autoComplete: 'honorific-prefix',
    })
    .trim()
    .max(255)
    .nullable(),
  name: yup.string()
    .label("Name")
    .meta({
      autoComplete: 'name',
    })
    .trim()
    .max(255)
    .nullable(),
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .max(255)
    .nullable()
    .when('set_password', (setPassword, schema) => {
      return setPassword ? schema.required("Die Login-Berechtigung erfordert eine E-Mail-Adresse.") : schema;
    }),
  set_password: yup.boolean().default(false),
  new_username: yup.ref('email'),
  new_password: yup.string()
    .label("Passwort")
    .max(255)
    .notRequired()
    .meta({password: true})
    .when('set_password', (setPassword, schema) => {
      return setPassword ? (
        schema.min(8, "Passwort ist zu kurz (min. 8 Zeichen benötigt)")
      ) : schema.transform(() => null);
    }),
  passwordConfirmation: yup.string()
    .label("Passwort (Wiederholung)")
    .max(255)
    .notRequired()
    .meta({password: true})
    .when('set_password', (setPassword, schema) => {
      return setPassword ? (
        schema.oneOf([yup.ref('new_password'), null], "Passwörter müssen übereinstimmen.")
      ) : schema.transform(() => null);
    }),
  allow_mails: yup.boolean()
    .label("E-Mail-Benachrichtigungen / Newsletter aktivieren"),
});

export const CHANGE_CONTACT_PASSWORD_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  old_password: yup.string()
    .label("Bisheriges Passwort")
    .max(255)
    .required()
    .meta({password: true}),
  new_password: yup.string()
    .label("Neues Passwort")
    .max(255)
    .required()
    .meta({password: true})
    .min(8, "Passwort ist zu kurz (min. 8 Zeichen benötigt)"),
  passwordConfirmation: yup.string()
    .label("Neues Passwort (Wiederholung)")
    .max(255)
    .required()
    .meta({password: true})
    .oneOf([yup.ref('new_password'), null], "Passwörter müssen übereinstimmen."),
});

export const CHANGE_EMAIL_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .max(255),
  new_username: yup.ref('email'),
});

export const DISABLE_2FA_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  password: yup.string()
    .label("Passwort")
    .max(255)
    .required()
    .meta({password: true}),
  delete_totp_devices: yup.bool(),
});

export const SETUP_2FA_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  tfa_key: yup.string()
    .required(),
  tfa_digits: yup.number()
    .required(),
  tfa_step: yup.number()
    .required(),
  otp_token: yup.string()
    .label("Code")
    .required(),
});

export const REGISTER_USER_SCHEMA = yup.object().shape({
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .required()
    .max(255),
  username: yup.ref('email'),
  password: yup.string()
    .label("Passwort")
    .max(255)
    .required()
    .min(8, "Passwort ist zu kurz (min. 8 Zeichen benötigt)")
    .meta({password: true}),
  passwordConfirmation: yup.string()
    .label("Passwort (Wiederholung)")
    .max(255)
    .required()
    .oneOf([yup.ref('password'), null], "Passwörter müssen übereinstimmen.")
    .meta({password: true}),
});

export const ORGANIZATION_CONTACT_ROLE_SCHEMA = yup.object().shape({
  contact: CONTACT_SCHEMA,
  organization: yup.string().nullable(),
  is_gl: yup.boolean()
    .label("Ansprechpartner für Geschäftsleitung"),
  is_technik: yup.boolean()
    .label("Ansprechpartner Technik/Musikredaktion"),
  is_lira: yup.boolean()
    .label("Ansprechpartner für LIRA"),
  perm_read_reports: yup.boolean()
    .label("Sendemeldungen lesen"),
  perm_write_reports: yup.boolean()
    .label("Sendemeldungen schreiben"),
  perm_submit_reports: yup.boolean()
    .label("Sendemeldungen veröffentlichen"),
  perm_read_music: yup.boolean()
    .label("Musik lesen"),
  perm_write_music: yup.boolean()
    .label("Musik bearbeiten"),
  perm_read_details: yup.boolean()
    .label("Stammdaten lesen"),
  perm_write_details: yup.boolean()
    .label("Stammdaten bearbeiten"),
  perm_read_stations: yup.boolean()
    .label("Programme lesen"),
  perm_write_stations: yup.boolean()
    .label("Programme bearbeiten"),
  perm_read_people: yup.boolean()
    .label("Personen lesen"),
  perm_write_people_and_rights: yup.boolean()
    .label("Personen bearbeiten und Zugriffsrechte verwalten"),
  perm_read_documents: yup.boolean()
    .label("Vertragsdokumente lesen"),
  perm_write_documents: yup.boolean()
    .label("Vertragsdokumente schreiben"),
    // .when('perm_read_documents', (permReadDocuments, schema) =>
    //   !permReadDocuments || true ? schema.transform(() => false) : schema
    // ),
});

export const DATABASE_RIGHT_SCHEMA = yup.object().shape({
  organization: yup.string().nullable()
    .label("Berechtigtes Sendeunternehmen")
    .meta({
      options: {
        null: "",
      },
    }),
  database: yup.string().nullable(),
  can_write: yup.boolean()
    .label("Hinzufügen neuer Musikproduktionen erlauben"),
});

export const REPORT_REQUIREMENTS_SCHEMA = yup.object().shape({
  id: yup.string()
    .nullable(),
  organization: yup.string()
    .nullable(),
  type: yup.string()
    .label("Prüfintervall")
    .meta({
      options: {
        PER_HOUR: "pro Stunde",
        PER_DAY: "pro Tag",
        PER_WEEKDAY: "pro Wochentag",
        PER_WEEK: "pro Woche",
        PER_MONTH: "pro Monat",
      },
    }),
  required_musik_dauer: yup.string()
    .label("Mindestsendedauer")
    .required(),
  required_musik_dauer_mon: yup.string()
    .label("Mindestsendedauer Montag")
    .required(),
  required_musik_dauer_tue: yup.string()
    .label("Mindestsendedauer Dienstag")
    .required(),
  required_musik_dauer_wed: yup.string()
    .label("Mindestsendedauer Mittwoch")
    .required(),
  required_musik_dauer_thu: yup.string()
    .label("Mindestsendedauer Donnerstag")
    .required(),
  required_musik_dauer_fri: yup.string()
    .label("Mindestsendedauer Freitag")
    .required(),
  required_musik_dauer_sat: yup.string()
    .label("Mindestsendedauer Samstag")
    .required(),
  required_musik_dauer_sun: yup.string()
    .label("Mindestsendedauer Sonntag")
    .required(),
  use_typical_musik_dauer: yup.bool()
    .label("typische Sendedauer festlegen")
    .required(),
  typical_musik_dauer: yup.string()
    .label("Typische Sendedauer")
    .required(),
  typical_musik_dauer_mon: yup.string()
    .label("Typische Sendedauer Montag")
    .required(),
  typical_musik_dauer_tue: yup.string()
    .label("Typische Sendedauer Dienstag")
    .required(),
  typical_musik_dauer_wed: yup.string()
    .label("Typische Sendedauer Mittwoch")
    .required(),
  typical_musik_dauer_thu: yup.string()
    .label("Typische Sendedauer Donnerstag")
    .required(),
  typical_musik_dauer_fri: yup.string()
    .label("Typische Sendedauer Freitag")
    .required(),
  typical_musik_dauer_sat: yup.string()
    .label("Typische Sendedauer Samstag")
    .required(),
  typical_musik_dauer_sun: yup.string()
    .label("Typische Sendedauer Sonntag")
    .required(),
  consider_holiday_as_sunday: yup.boolean()
    .label("Feiertage wie Sonntage behandeln"),
  holiday_state: yup.string()
    .label("Bundesland für Feiertage")
    .meta({
      options: {
        null: "nur bundeseinheitliche Feiertage",
        BW: "Baden-Württemberg",
        BY: "Bayern",
        BE: "Berlin",
        BB: "Brandenburg",
        HB: "Bremen",
        HH: "Hamburg",
        HE: "Hessen",
        MV: "Mecklenburg-Vorpommern",
        NI: "Niedersachsen",
        NW: "Nordrhein-Westfalen",
        RP: "Rheinland-Pfalz",
        SL: "Saarland",
        SN: "Sachsen",
        ST: "Sachsen-Anhalt",
        SH: "Schleswig-Holstein",
        TH: "Thüringen",
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
});

export const STATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  gemagvl_sender_prg_id: yup.string().nullable()
    .label("Programmkennzeichen (von GEMA/GVL vergeben)"),
  name: yup.string().nullable()
    .label("Name"),
  // gemagvl4_sender: yup.string().nullable()
  //     .label("SENDER laut GEMAGVL4"),
  // gemagvl4_prognr: yup.number("PROGNR muss eine Zahl sein.")
  //     .transform((value, originalValue) => ((!originalValue && _.isNaN(value)) ? undefined : value))
  //     .default(0)
  //     .integer()
  //     .min(0)
  //     .max(9)
  //     .meta({
  //         options: {
  //             0: "0",
  //             1: "1",
  //             2: "2",
  //             3: "3",
  //             4: "4",
  //             5: "5",
  //             6: "6",
  //             7: "7",
  //             8: "8",
  //             9: "9",
  //         },
  //     })
  //     .label("PROGNR laut GEMAGVL4"),
  report_reminders: yup.boolean()
    .nullable()
    .label("Fristerinnerungen aktivieren"),
  max_overlapping_seconds: yup.number("Wert muss eine Zahl sein")
    .transform((value, originalValue) => ((!originalValue && _.isNaN(value)) ? null : value))
    .nullable()
    .integer()
    .min(0)
    .label("Maximale Crossfading-/Überlappungszeit (Sekunden)"),
  standard_requirements: REPORT_REQUIREMENTS_SCHEMA,
  report_jingles: yup.boolean()
    .nullable()
    .label("Jingle-Meldungen aktivieren"),
  jingle_requirements: REPORT_REQUIREMENTS_SCHEMA
    .when('report_jingles', (reportJingles, schema) => {
      return reportJingles !== false ? schema.required() : yup.object().transform(() => undefined);
    }),
  // required_daily_standard_musik_dauer: yup.string()
  //   .label("Mindestsendedauer pro Tag (Musik)")
  //   .required(),
  // required_daily_jingle_musik_dauer: yup.string()
  //   .label("Mindestsendedauer pro Tag (Jingles)")
  //   .when('report_jingles', (reportJingles, schema) => {
  //     return reportJingles ? schema.required() : schema.transform(() => undefined);
  //   }),
  collective_jingle_report_default_covered_months: yup.number("Anzahl Monate muss eine Zahl sein")
    .label("Typischer Meldezeitraum")
    .transform((value, originalValue) => (((!originalValue || originalValue === 'null') && _.isNaN(value)) ? null : value))
    .nullable()
    .integer()
    .min(0)
    .max(12)
    .meta({
        options: {
            null: "nicht festgelegt",
            1: "1 Monat (Monatsmeldung)",
            2: "2 Monate",
            3: "3 Monate (Quartalsmeldung)",
            4: "4 Monate",
            6: "6 Monate (Halbjahresmeldung)",
            12: "12 Monate (Jahresmeldung)",
        },
    })
    .when('report_jingles', (reportJingles, schema) => {
      return reportJingles ? schema : schema.transform(() => undefined);
    }),
});

export const MUSIK_PRODUKTION_ID_TYP_KNZ = {
  ISRC: "ISRC",
  EAN_UPC: "EAN/UPC",
  KATALOG_NR: "Katalog- / Bestellnummer",
  GEMA_WERK_NR: "GEMA-Werknummer",
  ICE_WORK_KEY: "ICE",
  GVL_PRODUKT_ID: "GVL-Produkt-ID",
  MPN_ID: "MPN-ID",
  GRID: "GRID",
  ISWC: "ISWC",
  MUSIK_ARCHIV_NR: "Archivnummer",
};

export const DEKLARATION_TONAUFNAHME_KNZ = {
  LABLC: "Label mit Labelcode",
  LABEL: "Label ohne Labelcode",
  PROMO: "Promo-CD",
  SELFRELEASED: "self-released",
  OHNE: "ohne Deklaration",
};

export const MUSIC_PRODUCTION_ID_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  id_typ_knz: yup.string()
    .label("Typ")
    .meta({
      options: {
        ...MUSIK_PRODUKTION_ID_TYP_KNZ,
      },
    })
    .nullable(),
  id_wert: yup.string()
    .label("ID")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(250)
    .nullable(),
});

export const ORGANIZATION_DETAILS_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  company_name: yup.string()
    .label("Firmenname")
    .meta({
      autoComplete: 'company-name',
    })
    .trim()
    .max(255)
    .nullable(),
  display_name: yup.string()
    .label("Anzeigename")
    .meta({
      autoComplete: 'display-company-name',
      helperText: "Optionale abweichende Schreibweise Ihres Firmennamens für die Anzeige in der Titelzeile.",
    })
    .trim()
    .max(255)
    .nullable(),
  address: yup.string()
    .label("Adresse")
    .meta({
      autoComplete: 'address',
      rows: 7,
    })
    .trim()
    .max(255)
    .nullable(),
  gemagvlxml_lieferant_id: yup.string()
    .label("GEMAGVL-XML-Lieferant-ID")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  standard_musik_herkunft_knz: yup.string()
    .label("Musikherkunft Ihrer Musik")
    .meta({
      options: {
        AUTO: "automatisch ermitteln",
        NEVER_EIGEN: "automatisch ermitteln (ohne Eigenproduktionen)",
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  jingle_musik_herkunft_knz: yup.string()
    .label("Musikherkunft Ihrer Jingles ohne Identifier")
    .meta({
      options: {
        null: "(keine Angabe)",
        ...MUSIK_HERKUNFT_KNZ,
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  gemagvl4_encoding_preferences: yup.array()
    .label("GEMAGVL4-Zeichensatz")
    .meta({
      options: {
        auto: "(automatische Erkennung)",
        ...Object.fromEntries(ENCODINGS.map((x) => ([x, x]))),
      },
      autoComplete: 'charset',
    })
    .transform((value, originalValue) => (
      originalValue === 'null' ? (
        undefined
      ) : originalValue === 'auto' ? (
        ["auto"]
      ) : !originalValue ? (
        null
      ) : typeof originalValue === 'string' ? (
        [originalValue]
      ) : originalValue
    ))
    .nullable(),
  gemagvl4_timezone: yup.string()
    .label("GEMAGVL4-Zeitzone")
    .meta({
      options: {
        ...TIMEZONES,
      },
      autoComplete: 'charset',
    })
    .nullable(),
});

export const ERSCHIENENE_TONAUFNAHME_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  deklaration_tonaufnahme_knz: yup.string()
    .label("Deklaration Tonaufnahme")
    .meta({
      options: {
        ...DEKLARATION_TONAUFNAHME_KNZ,
      },
    })
    .when('label_code', (labelCode, schema) => (
      labelCode ? (
        schema.transform((value, originalValue) => (
          (!value || value === 'LABEL') ? 'LABLC' : value
        ))
      ) : schema
    ))
    .when('label', (label, schema) => (
      label ? (
        schema.transform((value, originalValue) => (
          !value ? 'LABEL' : value
        ))
      ) : schema
    ))
    .nullable(),
  label: yup.string()
    .label("Label")
    .trim()
    .max(50)
    .nullable(),
  label_code: yup.number("Label-Code muss eine Zahl sein")
    .transform((value, originalValue) => ((!originalValue && _.isNaN(value)) ? null : value))
    // .default(0)
    .nullable()
    .integer()
    .min(0)
    .max(99999999)
    .label("Label-Code"),
  album_titel: yup.string()
    .label("Album-Titel")
    .trim()
    .max(250)
    .nullable(),
  voe_datum: yup.string().meta({date: true}).label("Datum der Veröffentlichtung").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue, {representation: 'date'});
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
  sonstige_info: yup.string()
    .label("sonstige Informationen")
    .trim()
    .max(250)
    .nullable(),
});

export const MUSIK_PERSON_ROLLE_KNZ = {
  K: "Komponist",
  B: "Bearbeiter",
  SB: "Subbearbeiter",
  T: "Texter",
  ST: "Subtextdichter",
  TS: "Spezialtextdichter",
  V: "Verlag",
  OV: "Originalverlag",
  SV: "Subverlag",
  INT: "Interpret",
  DIRIG: "Dirigent",
  ENS: "Ensemble",
  GRUPPE: "Gruppe",
  ORCH: "Orchester",
  SON: "sonstige Person",
};

export const RECHT_KNZ = {
  KL: "Kleines Recht",
  GR: "Großes Recht",
};

export const NUTZUNG_ART_KNZ = {
  STANDARD: "Standard",
  JINGLE: "Jingle",
};

export const BESETZUNG_KNZ = {
  INSTR_2: "Instrumentalwerk mit 1-2 Instrumentalstimmen",
  INSTR_9: "Instrumentalwerk mit 3-9 Instrumentalstimmen",
  CHOR_KL: "kleiner Chor",
  CHOR_GR: "großer Chor",
  KLEINES_ORCH: "kleines Orchester",
  GROSSES_ORCH: "großes Orchester",
  ELEKTRO: "elektronische Musik",
  BUEHNE: "Bühnenmusik",
};

export const MUSIC_PERSON_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  name: yup.string()
    .label("Name")
    .trim()
    .meta({
      suggestions: true,
    })
    .max(90)
    .required(),
  vorname: yup.string()
    .label("Vorname")
    .trim()
    .max(45)
    .nullable(),
  musik_person_rolle_knz: yup.string()
    .label("Rolle")
    .meta({
      options: {
        ...MUSIK_PERSON_ROLLE_KNZ,
      },
    })
    .nullable(),
});

export const MUSIC_WORK_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  musik_titel: yup.string()
    .label("Titel")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(250)
    .nullable(),
  recht_knz: yup.string()
    .label("Recht")
    .meta({
      options: {
        ...RECHT_KNZ,
      },
    }),
  besetzung_knz: yup.string()
    .label("Besetzung")
    .meta({
      options: {
        null: "(leer)",
        ...BESETZUNG_KNZ,
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  gattung: yup.string()
    .label("Gattung")
    .trim()
    .max(250)
    .nullable(),
  nutzung_art_knz: yup.string()
    .label("Nutzungsart")
    .meta({
      options: {
        ...NUTZUNG_ART_KNZ,
      },
    })
    .nullable(),
  is_silence: yup.boolean()
    .label("Musikproduktion ist Platzhalter- / Stille-Datei"),
  aufnahme_datum: yup.string().meta({datetime: true}).label("Aufnahmedatum").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue);
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
  prod_titel: yup.string()
    .label("Name der Produktion")
    .trim()
    .max(250)
    .nullable(),
  prod_ort: yup.string()
    .label("Ort der Aufführung / Produktion")
    .trim()
    .max(250)
    .nullable(),
  musik_herkunft_knz: yup.string()
    .label("Musikherkunft")
    .meta({
      options: {
        null: "(keine Angabe)",
        ...MUSIK_HERKUNFT_KNZ,
      },
    })
    .transform((value, originalValue) => (originalValue === 'null' ? null : originalValue))
    .nullable(),
  // erschienene_tonaufnahme: ERSCHIENENE_TONAUFNAHME_SCHEMA,
});

export const GEMAGVLXML_AUSSTRAHLUNG_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  gemagvlxml_lieferung: yup.string().nullable(),
  datum_uhrzeit_von: yup.string().meta({datetime: true}).label("Ausstrahlungsbeginn").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue);
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
  datum_uhrzeit_bis: yup.string().meta({datetime: true}).label("Ausstrahlungsende").transform(
    (value, originalValue) => {
      if (originalValue instanceof Date) {
        try {
          value = formatISO(originalValue);
        } catch (e) {
          console.log({e, value});
        }
      }
      return value;
    },
  ).nullable(),
});

export const REPORT_CANCEL_REQUEST_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  cancellation_request_reason: yup.string()
    .label("Grund der Stornierung")
    .meta({
      rows: 5,
    }),
});

export const COLLECTIVE_REPORT_COVERED_MONTHS = yup.object().shape({
  id: yup.string().nullable(),
  organization: yup.string().nullable(),
  collective_report_covered_months: yup.number("Anzahl Monate muss eine Zahl sein")
    .label("Meldezeitraum dieser Lieferung")
    .transform((value, originalValue) => (((!originalValue || originalValue === 'null') && _.isNaN(value)) ? null : value))
    .nullable()
    .integer()
    .min(0)
    .max(12)
    .meta({
        options: {
            null: "nicht festgelegt",
            1: "1 Monat (Monatsmeldung)",
            2: "2 Monate",
            3: "3 Monate (Quartalsmeldung)",
            4: "4 Monate",
            5: "5 Monate",
            6: "6 Monate (Halbjahresmeldung)",
            7: "7 Monate",
            8: "8 Monate",
            9: "9 Monate",
            10: "10 Monate",
            11: "11 Monate",
            12: "12 Monate (Jahresmeldung)",
        },
    }),
});

export const FEEDBACK_SCHEMA = yup.object().shape({
  name: yup.string().nullable()
    .label("Name"),
  email: yup.string()
    .label("E-Mail")
    .meta({
      autoComplete: 'email',
    })
    .email("Dieses Feld muss eine gültige E-Mail-Adresse enthalten.")
    .max(255)
    .nullable(),
  message: yup.string()
    .label("Feedback")
    .meta({
      autoComplete: 'message',
      rows: 7,
    })
    .trim()
    .max(16384)
    .required(),
});

export const DOCUMENT_SCHEMA = yup.object().shape({
  organization: yup.string().nullable()
    .label("Empfänger"),
  // name: yup.string().nullable()
  //   .label("Name"),
  description: yup.string().nullable()
    .label("Betreff"),
  message: yup.string()
    .label("Nachricht")
    .meta({
      autoComplete: 'message',
      rows: 7,
    })
    .trim()
    .max(128*1024),
});

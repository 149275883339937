import MuiTextField from "@mui/material/TextField";
import React, {useRef, useState} from "react";
import _ from "lodash";
import {Autocomplete, CircularProgress} from '@mui/material';
import {Controller} from "react-hook-form";

export default React.forwardRef(
  ({
    errors = {},
    control,
    name,
    fullWidth = true,
    suggestions = [],
    variant,
    register,
    TextFieldComponent = MuiTextField,
    autocompleteProps = {},
    autocompleteProcessOnChangeData,
    onFocus,
    onBlur,
    freeSolo = true,
    defaultValue = null,
    ...props
  }, forwardedRef) => {
    const error = _.get(errors, name);

    const {
      ref: inputRef,
      ...rest
    } = register(name);

    const loading = (suggestions === null);
    if (suggestions === null) {
      suggestions = [];
    }

    const [focus, setFocus] = useState(false);

    const autocompleteRef = useRef(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            onChange,
            value,
            ref,
            ...fieldProps
          }
        }) => (
          <Autocomplete
            id={name}
            ref={autocompleteRef}
            freeSolo={freeSolo}
            options={suggestions}
            loading={loading}
            value={value || defaultValue}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
            {...fieldProps}
            {...autocompleteProps}
            onChange={(e, data) => {
              let processedData = data;
              if (autocompleteProcessOnChangeData) {
                processedData = autocompleteProcessOnChangeData(data);
              }
              return onChange(processedData);
            }}
            renderInput={(params) => (
              <TextFieldComponent
                {...props}
                {...params}
                ref={forwardedRef}
                inputRef={ref}
                variant={variant || "standard"}
                onChange={(e) => onChange(e.target.value)}
                fullWidth={fullWidth}
                error={!!error}
                helperText={error?.message}
                onFocus={(e) => {
                  if (onFocus) {
                    onFocus(e);
                  }
                  return setFocus(true);
                }}
                onBlur={(e) => {
                  if (onBlur) {
                    onBlur(e);
                  }
                  return setFocus(false);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && focus && isMenuOpen ? <CircularProgress color="inherit" size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}/>
        )}
      />
    );
  },
);

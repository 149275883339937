import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash';
import {api, AuthenticationError} from "src/api/api";
import {logout} from "src/features/session";
import {normalize} from "normalizr";
import {
  deleteEntity,
  getDatabaseGetter, getDatabaseRightGetter,
  getErschieneneTonaufnahmeGetter,
  getGVLProductGetter, getLookupTableGetter,
  getOrganizationGetter,
  getOrgMusicWorkGetter,
  registerEntityObserver,
  setEntities,
  unregisterEntityObserver
} from "src/features/entity/entity-slice";
import {useCallback, useEffect, useState} from "react";
import {getSelectedOrganizationId, getVersion, setBackendVersion} from "src/features/dashboard";

const wrapApiFunc = _.memoize(dispatch => _.memoize(version => _.memoize((schema) => _.memoize((apiFunc) => async (url, validatedData, entityOptions = {}, ...args) => {
  const {createEntities, organization} = entityOptions;

  let response;
  try {
    response = await apiFunc(url, validatedData, ...args);
  } catch (e) {
    if (e instanceof AuthenticationError) {
      dispatch(logout());
    }
    throw e;
  }

  const latestVersion = response?.headers?.['x-sendemeldung-version'];
  if (latestVersion && (version === null || (version && version !== latestVersion))) {
    dispatch(setBackendVersion({version: latestVersion}));
  }

  let data = response.data;
  let normalizedData = undefined;

  if (data?.next !== undefined && data?.previous !== undefined && data?.results !== undefined) {
    data = data.results;
  }

  if (schema) {
    normalizedData = normalize(data, schema);
    dispatch(setEntities({...normalizedData, created: createEntities, createdForOrganization: organization}));
  }

  return {
    response,
    data,
    normalizedData,
  };
}))));

export function getEntityApi(schema, dispatch, version) {
  return Object.fromEntries(
    Object.entries(api).map(([name, apiFunc]) => [name, wrapApiFunc(dispatch)(version)(schema)(apiFunc)])
  );
}

export function useEntityApi(schema) {
  const dispatch = useDispatch();
  const version = useSelector(getVersion);
  return getEntityApi(schema, dispatch, version);
  //
  // return Object.fromEntries(
  //   Object.entries(api).map(([name, apiFunc]) => [name, wrapApiFunc(dispatch)(schema)(apiFunc)])
  // );
}

export function useEntityDeleter({entityType, baseUrl}) {
  const dispatch = useDispatch();
  const entityApi = useEntityApi();

  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  const [deletingUuids, setDeletingUuids] = useState(new Set());
  const [deletedUuids, setDeletedUuids] = useState(new Set());
  const [deletionFailedUuids, setDeletionFailedUuids] = useState(new Set());

  const entityDeleter = useCallback(async (id) => {
    setDeletingUuids(new Set(deletingUuids).add(id));

    try {
      try {
        await entityApi.delete(`${baseUrl}${id}/`);
      } catch (e) {
        if (e.response?.status === 404) {
          // This is fine.
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw e;
        }
      }
      dispatch(deleteEntity(entityType, id));
      setDeletedUuids(new Set(deletedUuids).add(id));
    } catch (e) {
      setDeletionFailedUuids(new Set(deletionFailedUuids).add(id));
      throw e;
    } finally {
      let newDeletingUuids = new Set(deletingUuids);
      newDeletingUuids.delete(id);
      setDeletingUuids(newDeletingUuids);
    }

    return true;
  }, [deletingUuids, setDeletingUuids, deletedUuids, setDeletedUuids, deletionFailedUuids, setDeletionFailedUuids, dispatch, entityType, baseUrl]);

  return {
    deletingUuids,
    deletedUuids,
    deletionFailedUuids,
    deleteEntity: entityDeleter,
  };
}

export function useEntityObserver(props) {
  const {type, id, ids} = props || {};
  const dispatch = useDispatch();

  useEffect(() => {
    let relevantIds;
    if (ids) {
      relevantIds = [...ids];
    } else {
      relevantIds = [];
    }
    if (id) {
      relevantIds.push(id);
    }

    for (const id of relevantIds) {
      dispatch(registerEntityObserver({type, id}));
    }
    return () => {
      for (const id of relevantIds) {
        dispatch(unregisterEntityObserver({type, id}));
      }
    };
  }, [dispatch, type, id, ids]);
}

export function useOrgMusicWork({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'org_music_work', id: observeId});

  const getOrgMusicWork = useSelector(getOrgMusicWorkGetter);
  return getOrgMusicWork(id);
}

export function useErschieneneTonaufnahme({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'erschienene_tonaufnahme', id: observeId});

  const getErschieneneTonaufnahme = useSelector(getErschieneneTonaufnahmeGetter);
  return getErschieneneTonaufnahme(id);
}

export function useGVLProduct({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'gvl_product', id: observeId});

  const getGVLProduct = useSelector(getGVLProductGetter);
  return getGVLProduct(id);
}

export function useDatabase({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'database', id: observeId});

  const getDatabase = useSelector(getDatabaseGetter);
  return getDatabase(id);
}

export function useDatabaseRight({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'database_right', id: observeId});

  const getDatabaseRight = useSelector(getDatabaseRightGetter);
  return getDatabaseRight(id);
}

export function useLookupTable({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'lookup_table', id: observeId});

  const getLookupTable = useSelector(getLookupTableGetter);
  return getLookupTable(id);
}

export function useOrganization({id, observe=true}) {
  const observeId = observe ? id : null;
  useEntityObserver({type: 'organization', id: observeId});

  const getOrganization = useSelector(getOrganizationGetter);
  return getOrganization(id);
}

export function useSelectedOrganization() {
  const organizationId = useSelector(getSelectedOrganizationId);
  return useSelector(getOrganizationGetter)(organizationId);
}

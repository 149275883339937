import React from "react";
import {useHasPermissions} from "src/features/dashboard/dashboard-hooks";

export default function OnlyIfPermissions({
  children,
  containerComponent: Component,
  ...props
}) {
  const permissions = Object.fromEntries(
    Object.entries(props).filter(([key, val]) => key.startsWith('perm_'))
  );
  const otherProps = Object.fromEntries(
    Object.entries(props).filter(([key, val]) => !key.startsWith('perm_'))
  );

  const hasPermissions = useHasPermissions(permissions);

  return (
    hasPermissions ? (
      Component ? (
        <Component {...otherProps}>
          {children}
        </Component>
      ) : (
        children
      )
    ) : null
  );
}

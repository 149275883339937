import {schema} from 'normalizr';

const createEntity = (name, schemaDefinition, options) => (new schema.Entity(name, schemaDefinition, options));

export const CONTACT = createEntity('contacts');
export const CONTACTS = [CONTACT];

export const DATABASE = createEntity('databases');
export const DATABASES = [DATABASE];

export const LOOKUP_TABLE = createEntity('lookup_tables');
export const LOOKUP_TABLES = [LOOKUP_TABLE];

export const USER = createEntity('users');

export const CONTACT_ROLE = createEntity('contact_roles', {
  contact: CONTACT,
});
export const CONTACT_ROLES = [CONTACT_ROLE];

export const DATABASE_RIGHT = createEntity('database_rights', {
  database: DATABASE,
});
export const DATABASE_RIGHTS = [DATABASE_RIGHT];

export const STATION = createEntity('stations', {
});
export const STATIONS = [STATION];

export const GEMAGVL4_STATION = createEntity('gemagvl4_stations', {
  station: STATION,
});
export const GEMAGVL4_STATIONS = [GEMAGVL4_STATION];

export const ORGANIZATION = createEntity('organizations', {
  contacts: CONTACTS,
  contact_roles: CONTACT_ROLES,
  stations: STATIONS,
});
export const ORGANIZATIONS = [ORGANIZATION];

export const TODO_TASK = createEntity('todo_tasks');
export const TODO_TASKS = [TODO_TASK];

export const MUSIK_PERSON = createEntity('musik_personen');
export const MUSIK_PERSONEN = [MUSIK_PERSON];

export const MUSIK_PRODUKTION_ID = createEntity('musik_produktion_ids');
export const MUSIK_PRODUKTION_IDS = [MUSIK_PRODUKTION_ID];

export const ERSCHIENENE_TONAUFNAHME = createEntity('erschienene_tonaufnahmen');
export const ERSCHIENENE_TONAUFNAHMEN = [ERSCHIENENE_TONAUFNAHME];

export const GVL_PRODUCT = createEntity('gvl_products', {
});
export const GVL_PRODUCTS = [GVL_PRODUCT];

export const GEMA_WORK = createEntity('gema_works', {
});
export const GEMA_WORKS = [GEMA_WORK];

export const LABEL = createEntity('labels', {
});
export const LABELS = [LABEL];

export const ORG_MUSIC_WORK = createEntity('org_music_works', {
  musik_personen: MUSIK_PERSONEN,
  musik_produktion_ids: MUSIK_PRODUKTION_IDS,
  erschienene_tonaufnahme: ERSCHIENENE_TONAUFNAHME,
  todo_tasks: TODO_TASKS,
  linked_gvl_produkt: GVL_PRODUCT,
});
export const ORG_MUSIC_WORKS = [ORG_MUSIC_WORK];

export const ORG_MUSIC_WORK_LOG = createEntity('org_music_work_log', {
});
export const ORG_MUSIC_WORK_LOGS = createEntity('org_music_work_logs', {
  logs: [ORG_MUSIC_WORK_LOG],
});

export const UPLOADED_FILE = createEntity('uploaded_files');
export const UPLOADED_FILES = [UPLOADED_FILE];

export const DOCUMENT = createEntity('documents');
export const DOCUMENTS = [DOCUMENT];

export const GEMAGVL4_LIEFERUNG = createEntity('gemagvl4_lieferungen',
{
  upload: UPLOADED_FILE,
  organization: ORGANIZATION,
  stations: STATIONS,
});
export const GEMAGVL4_LIEFERUNGEN = [GEMAGVL4_LIEFERUNG];

export const GEMAGVL4_AUSSTRAHLUNG = createEntity('gemagvl4_ausstrahlungen', {
  gemagvl4_lieferung: GEMAGVL4_LIEFERUNG,
  gemagvl4_station: GEMAGVL4_STATION,
  todo_tasks: TODO_TASKS,
});
export const GEMAGVL4_AUSSTRAHLUNGEN = [GEMAGVL4_AUSSTRAHLUNG];

export const GEMAGVLXML_LIEFERUNG = createEntity('gemagvlxml_lieferungen', {
  upload: UPLOADED_FILE,
  gemagvl4_lieferung: GEMAGVL4_LIEFERUNG,
  organization: ORGANIZATION,
  stations: STATIONS,
  todo_tasks: TODO_TASKS,
});
export const GEMAGVLXML_LIEFERUNGEN = [GEMAGVLXML_LIEFERUNG];

export const GEMAGVLXML_LIEFERUNG_STATS = createEntity('gemagvlxml_lieferungen_stats', {
});
export const GEMAGVLXML_LIEFERUNGEN_STATS = [GEMAGVLXML_LIEFERUNG_STATS];

export const GEMAGVLXML_EXPORT = createEntity('gemagvlxml_exports', {
});
export const GEMAGVLXML_EXPORTS = [GEMAGVLXML_EXPORT];

export const KNOWN_BUG = createEntity('known_bugs');
export const KNOWN_BUGS = [KNOWN_BUG];

export const NOTIFICATION = createEntity('notifications');
export const NOTIFICATIONS = [NOTIFICATION];

export const USER_FEEDBACK = createEntity('user_feedback_pieces', {
  organization: ORGANIZATION,
});
export const USER_FEEDBACKS = [USER_FEEDBACK];

export const PASSWORD_RESET = createEntity('password_reset', {
})
export const PASSWORD_RESET_REQUEST = createEntity('password_reset_request', {
})

export const GEMAGVLXML_AUSSTRAHLUNG = createEntity('gemagvlxml_ausstrahlungen', {
  org_music_work: ORG_MUSIC_WORK,
  gemagvlxml_lieferung: GEMAGVLXML_LIEFERUNG,
  gemagvl4_ausstrahlung: GEMAGVL4_AUSSTRAHLUNG,
  station: STATION,
  todo_tasks: TODO_TASKS,
});
export const GEMAGVLXML_AUSSTRAHLUNGEN = [GEMAGVLXML_AUSSTRAHLUNG];

export const ENTITY_TYPES = {
  org_music_work: ORG_MUSIC_WORK,
  org_music_works: ORG_MUSIC_WORKS,
  org_music_work_log: ORG_MUSIC_WORK_LOG,
  org_music_work_logs: ORG_MUSIC_WORK_LOGS,
  gemagvlxml_lieferung: GEMAGVLXML_LIEFERUNG,
  gemagvlxml_lieferung_stats: GEMAGVLXML_LIEFERUNG_STATS,
  gemagvlxml_ausstrahlung: GEMAGVLXML_AUSSTRAHLUNG,
  gemagvlxml_export: GEMAGVLXML_EXPORT,
  gemagvl4_lieferung: GEMAGVL4_LIEFERUNG,
  gemagvl4_ausstrahlung: GEMAGVL4_AUSSTRAHLUNG,
  uploaded_file: UPLOADED_FILE,
  organization: ORGANIZATION,
  station: STATION,
  gemagvl4_station: GEMAGVL4_STATION,
  contact_role: CONTACT_ROLE,
  known_bug: KNOWN_BUG,
  document: DOCUMENT,
  notification: NOTIFICATION,
  todo_task: TODO_TASK,
  gvl_product: GVL_PRODUCT,
  gvl_products: GVL_PRODUCTS,
  gema_work: GEMA_WORK,
  gema_works: GEMA_WORKS,
  label: LABEL,
  labels: LABELS,
  database: DATABASE,
  databases: DATABASES,
  lookup_table: LOOKUP_TABLE,
  lookup_tables: LOOKUP_TABLES,
  database_right: DATABASE_RIGHT,
  database_rights: DATABASE_RIGHTS,
};

export const ENTITY_LIST_URLS = {
  todo_task: '/api/sendemeldung/organizations/{organizationId}/todo_tasks/',
  gemagvlxml_lieferung: '/api/sendemeldung/organizations/{organizationId}/gemagvlxml_lieferungen/',
  gemagvlxml_lieferung_stats: '/api/sendemeldung/organizations/{organizationId}/gemagvlxml_lieferungen_stats/',
  gemagvl4_lieferung: '/api/sendemeldung/organizations/{organizationId}/gemagvl4_lieferungen/',
  gemagvlxml_ausstrahlung: '/api/sendemeldung/organizations/{organizationId}/gemagvlxml_ausstrahlungen/',
  gemagvl4_ausstrahlung: '/api/sendemeldung/organizations/{organizationId}/gemagvl4_ausstrahlungen/',
  gemagvlxml_export: '/api/sendemeldung/organizations/{organizationId}/gemagvlxml_exports/',
  uploaded_file: '/api/sendemeldung/organizations/{organizationId}/uploaded_files/',
  org_music_work: '/api/sendemeldung/organizations/{organizationId}/org_music_works/',
  org_music_work_logs: '/api/sendemeldung/organizations/{organizationId}/org_music_work_logs/',
  station: '/api/sendemeldung/organizations/{organizationId}/stations/',
  gemagvl4_station: '/api/sendemeldung/organizations/{organizationId}/gemagvl4_stations/',
  contact_role: '/api/sendemeldung/organizations/{organizationId}/contact_roles/',
  database: '/api/sendemeldung/organizations/{organizationId}/databases/',
  database_right: '/api/sendemeldung/organizations/{organizationId}/database_rights/',
  lookup_table: '/api/sendemeldung/organizations/{organizationId}/lookup_tables/',
  document: '/api/sendemeldung/organizations/{organizationId}/documents/',
  notification: '/api/sendemeldung/organizations/{organizationId}/notifications/',
  organization: '/api/sendemeldung/organizations/',
  gvl_product: '/api/sendemeldung/refdata/gvl_products/',
  // gema_work: '/api/sendemeldung/refdata/gema_works/',
  label: '/api/sendemeldung/refdata/labels/',
};

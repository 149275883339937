import {createTheme, responsiveFontSizes} from '@mui/material';
import {deDE} from '@mui/material/locale';

// A custom theme for this app
const theme = createTheme({
  palette: {
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=ef6c00&secondary.color=1976D2
    primary: {
      main: '#ef6c00',
      light: '#ff9d3f',
      dark: '#b53d00',
    },
    secondary: {
      main: '#1976d2',
      light: '#63a4ff',
      dark: '#004ba0',
    },

    secondToolbar: {
      // main: '#1976d2',
      // light: '#63a4ff',
      // dark: '#004ba0',
      // contrastText: '#ffffff',
      //main: 'rgb(246, 246, 246)',
      main: '#fafafa',
      light: '#fefefe',
      dark: '#eeeeee',
      contrastText: '#333333',
    },

    warning: {
      main: '#ef6c00',
      light: '#ff9d3f',
      dark: '#b53d00',
    },
    info: {
      main: '#999999',
      light: '#aaaaaa',
      dark: '#888888',
      contrastText: '#fff',
    },
    magic: {
      main: '#999999',
      light: '#aaaaaa',
      dark: '#888888',
      contrastText: '#fff',
      // light: '#63a4ff',
      // dark: '#004ba0',
    },
    default: {
      main: '#555555',
      light: '#777777',
      dark: '#333333',
    },

    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: '#fafafa',
      paper: '#fff',
    },
  },

  components: {
    MuiAlert: { // Fix text color as suggested in https://github.com/mui/material-ui/issues/33512#issuecomment-1186825019.
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "filled" && {
            color: "#fff"
          })
        })
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.9rem',
          // backgroundColor: 'black',
        },
        arrow: {
          // color: 'black',
        },
      },
    },
  },
}, deDE);

export default responsiveFontSizes(theme);

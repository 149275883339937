import React from "react";
import {useSelector} from "react-redux";
import {getSelectedOrganization} from "src/features/dashboard";
import { Alert, AlertTitle } from '@mui/material';
import {Box, Divider} from "@mui/material";

export default function SystemStatusBanner() {
  const {ui_system_status_banner} = useSelector(getSelectedOrganization);

  if (!ui_system_status_banner) {
    return null;
  }

  return (
    <Box mb={4}>
      <Box mb={4}>
        <Alert variant="filled" severity="warning">
          <AlertTitle>Hinweis</AlertTitle>
          {ui_system_status_banner}
        </Alert>
      </Box>
      <Divider/>
    </Box>
  );
}

import {all, call, delay, put, race, select, take} from 'redux-saga/effects';
import {performProfileUpdate, setProfile} from './participation-slice';
import axios from 'axios';
import {takeLatestDeep} from "src/packages/effects";
import {withProgress} from "src/features/ui/progress";
import {getSessionData, loginSuccess, logout, logoutSuccess} from "src/features/session";

function* fetchProfile(sendeunternehmenId) {
  while (true) {
    try {
      const response = yield call(axios.get, `/api/teilnahme/profile/${sendeunternehmenId}/`);
      if (!response.data) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("fetch profile failed");
      }

      const profile = response.data;
      yield put(setProfile({profile}));
      break;
    } catch (e) {
      console.error(e);
      if (e.isAxiosError && e.response?.status === 401) {
        yield put(logout());
        break;
      }
      yield delay(500);
    }
  }
}

function* dataSaga() {
  while (true) {
    // Wait for session data to become available.
    let sendeunternehmenId;
    while (!sendeunternehmenId) {
      const sessionData = yield select(getSessionData);
      sendeunternehmenId = sessionData.sendeunternehmenId;

      if (!sendeunternehmenId) {
        // Wait for login or state rehydration.
        yield take([loginSuccess]);
      }
    }

    yield race({
      fetchProfile: call(fetchProfile, sendeunternehmenId),
      logoutTimeout: take([logoutSuccess]),
      timeout: delay(5000),
    });


    yield take([logoutSuccess]);
  }
}

const watchPerformProfileUpdate = withProgress(
  action => ({type: 'profileUpdate', id: action.payload.id}),
)(
  function* (action) {
    const {profile} = action.payload;

    const sessionData = yield select(getSessionData);
    const sendeunternehmenId = sessionData.sendeunternehmenId;

    if (!sendeunternehmenId) {
      const error = new Error();
      error.formErrors = {detail: "Sie sind nicht eingeloggt."};
      throw error;
    }

    try {
      const response = yield call(axios.patch, `/api/teilnahme/profile/${sendeunternehmenId}/`, profile);
      if (!response.data) {
        const error = new Error();
        error.formErrors = {detail: "Keine Verbindung zum Netzwerk."};
        throw error;
      }

      const updatedProfile = response.data;
      yield put(setProfile({profile: updatedProfile}));
    } catch (e) {
      console.error(e);
      if (e.isAxiosError && e.response?.status === 401) {
        yield put(logout());
        return;
      }

      const error = new Error();
      error.formErrors = e?.response?.data;
      throw error;
    }
  }
);

export default function* rootSaga() {
  yield all([
    call(dataSaga),
    takeLatestDeep(
      performProfileUpdate,
      action => action.payload.id,
      watchPerformProfileUpdate,
    ),
  ]);
}

import React, {useEffect} from 'react';
import {Box, Skeleton, TablePagination} from "@mui/material";
import {useDispatch} from "react-redux";
import {updateListing} from "src/features/ui/listing";

export default function ({currentPage, totalPages, count, setPage, setPageSize, pageSize, force, error, listingId}) {
  if (currentPage === undefined) {
    return null;
  }

  if (currentPage === 1 && totalPages === 1 && !force) {
    return null;
  }

  const handleChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const dispatch = useDispatch();

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    if (setPageSize) {
      setPageSize(rowsPerPage);
    } else {
      dispatch(updateListing({
        id: listingId,
        pageSize: rowsPerPage,
        currentPage: 1,
      }));
    }
  };

  useEffect(() => {
    if (totalPages && currentPage > totalPages) {
      setPage(totalPages);
    } else if (totalPages && currentPage < 1) {
      setPage(1);
    }
  }, [currentPage, totalPages]);

  return (
    <TablePagination
      sx={{ml: 0}}
      SelectProps={{sx: {display: 'none'}}}
      component="div"
      labelRowsPerPage={null}
      rowsPerPageOptions={[
        {value: 10, label: "10 pro Seite"},
        {value: 25, label: "25 pro Seite"},
        {value: 50, label: "50 pro Seite"},
        {value: 100, label: "100 pro Seite"},
      ]}
      backIconButtonProps={{title: "Vorherige Seite"}}
      nextIconButtonProps={{title: "Nächste Seite"}}
      labelDisplayedRows={({from, to, count}) => (
        <>
          {count !== -1 ? (
            `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`
          ) : (
            <Box style={{whiteSpace: 'nowrap'}} component="span">
              <Skeleton>
                <span>{from}–{to} von {to}</span>
              </Skeleton>
            </Box>
          )}
        </>
      )}
      count={count === undefined ? -1 : count}
      page={currentPage - 1}
      onPageChange={handleChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}

import React from 'react';
import {GlobalLayout} from "src/components/layout";

export const wrapPageElement = ({
  element,
  props
}) => (
  <GlobalLayout {...props}>
    {element}
  </GlobalLayout>
);

import {useHasMounted} from "src/packages/has-mounted-hook";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCallback, useEffect} from "react";
import _ from 'lodash';

export function useYupForm({schema, data, errors, submit, shouldUnregister = false, ...formProps}) {
  const hasMounted = useHasMounted();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: data,
    shouldUnregister,
    ...formProps,
  });
  const {handleSubmit, setError, clearErrors} = form;

  const submitAction = useCallback((event) => {
    event.preventDefault();
    return handleSubmit(submit)();
  }, [handleSubmit, submit]);

  useEffect(() => {
    if (!hasMounted) {
      // If we run clearErrors before the fields are registered, weird things happen.
      return;
    }
    if (!errors) {
      // TODO: Reset form submit state.
      clearErrors();
      return;
    }

    const processErrors = (errors, prefix) => {
      Object.entries(errors).forEach(([field, error]) => {
        if (prefix) {
          field = `${prefix}${field}`;
        }
        if (_.isPlainObject(error)) {
          processErrors(error, `${field}.`);
        } else {
          setError(field, {
            type: 'manual',
            message: error
          }, {shouldFocus: true});
        }
      });
    };
    processErrors(errors);
  }, [errors, clearErrors, setError, hasMounted]);

  return {form, hasMounted, submitAction};
}

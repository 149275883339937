import log from 'loglevel';
import axios from "axios";

const {serializeError} = require('serialize-error');

const isCompatible = true;

const reportLoggedErrorsToAPI = logger => {
  // based on https://github.com/artemyarulin/loglevel-serverSend/
  if (!logger || !logger.methodFactory) {
    throw new Error("loglevel instance has to be specified in order to be extended");
  }
  const originalMethodFactory = logger.methodFactory;
  logger.methodFactory = (methodName, logLevel) => {
    const rawMethod = originalMethodFactory(methodName, logLevel);

    return message => {
      if (typeof message === 'string' || message instanceof String) {
        message = {message};
      }

      const errorReport = {
        severity: logLevel,
        error: serializeError(message),
      };

      try {
        axios.post('/api/sendemeldung/telemetry/reportClientError/', errorReport);
      } catch (e) {
        console.error("Failed to report client-side error, giving up.", e);
        // Note that window.onerror must not be triggered as to avoid loops.
      }

      rawMethod(message);
    };
  };
  logger.setLevel(logger.levels.WARN);
};

// if compatible, log javascript errors to server
if (isCompatible) {
  try {
    reportLoggedErrorsToAPI(log);

    if (typeof window !== "undefined") {
      window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
        if (errorObj && errorObj.ignoreError) {
          return;
        }
        log.error({errorMsg, url, lineNumber, column, errorObj, errorType: typeof errorObj});
      }
    }
  } catch (e) {
    console.error(e);
  }
}

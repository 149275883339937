import {navigate} from "gatsby";
import React from "react";
import {Link} from "gatsby-theme-material-ui";

export default React.forwardRef(function HrefComponent({component: Component, href, children, noOnClick, wrapLink, external, onClick, ...props}, ref) {
  const result = (
    <Component
      ref={ref}
      href={href}
      onClick={noOnClick ? undefined : (evt) => {
        if (onClick) {
          const result = onClick(evt);
          if (!result) {
            return result;
          }
        }

        if (evt.ctrlKey) {
          return;
        }

        evt.preventDefault();
        evt.stopPropagation();

        if (window.location.pathname === href) {
          // Do not add to history.
          if (external) {
            window.location.replace(href);
          } else {
            navigate(href, {replace: true});
          }
        } else {
          if (external) {
            window.location.assign(href);
          } else {
            navigate(href);
          }
        }
      }}
      {...props}
    >
      {children}
    </Component>
  );

  if (wrapLink) {
    return (
      <Link
        href={href}
        underline="none"
        variant="inherit"
        color="inherit"
      >
        {result}
      </Link>
    );
  } else {
    return result;
  }
});

import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import {Link} from 'gatsby-theme-material-ui';
import {Helmet} from "react-helmet";
import {Box, Button, Grid, Paper, useMediaQuery, useTheme} from "@mui/material";
import backgroundImage from "./onstage.jpg";
import logo from "./sendemeldung_de.svg";
import {navigate} from "gatsby";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useSelector} from "react-redux";
import {getOrganizations} from "src/features/dashboard";
import {getProfile, hasProfileData} from "src/features/participation";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background: '#fff',
  },
  header: {
    flex: 0,
    [theme.breakpoints.up('lg')]: {
      minHeight: '4rem',
    },
  },
  main: {
    flex: 1,
  },
  mainInner: {
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
  pictureColumn: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    height: '100%',
    minHeight: '50vh',
    [theme.breakpoints.down('lg')]: {
      minHeight: '80vw',
    },
    flex: 1,
    backgroundPosition: 'center',
  },
  footer: {
    padding: theme.spacing(3, 2),
    textAlign: 'center',
    flex: 0,
    minHeight: '8rem',
  },
  footerLine: {
    paddingTop: theme.spacing(4),
  },
  headerBody: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  headerLeft: {
    textAlign: 'left',
    '& > *': {
      whiteSpace: 'nowrap',
    },
  },
  headerRight: {
    textAlign: 'right',
    '& > *': {
      whiteSpace: 'nowrap',
    },
  },
}));

export default function WelcomeDashboardLayout(
  {
    title = "Sendemeldung.de",
    titlePrefix,

    children,

    pictureBox,

    preferRows = false,

    loggedIn,
    logout,
    hideGotoDashboard,
  }
) {
  const classes = useStyles();

  const theme = useTheme();
  const narrow = useMediaQuery(theme.breakpoints.down('lg'));

  const hasOrganizations = useSelector(getOrganizations)?.length > 0;
  const hasProfile = useSelector(hasProfileData);

  const {
    has_credentials: hasCredentials,
    has_just_set_up_credentials: hasJustSetUpCredentials,
  } = useSelector(getProfile);

  const rows = (narrow || preferRows);

  return (
    <Grid container className={classes.root} direction='column' justifyContent='space-between'>
      <Helmet>
        <title>{titlePrefix ? `${titlePrefix} - ${title}` : title}</title>
      </Helmet>

      <Grid item className={classes.header}>
        <noscript>
          <Container maxWidth="lg" component="header">
            <Alert severity="error">
              <AlertTitle>JavaScript erforderlich</AlertTitle>
              Bitte aktivieren Sie JavaScript, um diese Website zu nutzen.
            </Alert>
          </Container>
        </noscript>
        {loggedIn ? (
          <Container maxWidth="lg" component="header">
            <Grid container spacing={4} className={classes.headerBody}>
              <Grid item xs={6} className={classes.headerLeft}>
                {hasOrganizations && (hasCredentials || hasJustSetUpCredentials) ? (
                  <Button color="primary" onClick={() => navigate('/dashboard/')}>zum Dashboard</Button>
                ) : !hideGotoDashboard && hasProfile ? (
                  <Button color="primary" onClick={() => navigate('/teilnahme/dashboard/')}>Ihre Teilnahme</Button>
                ) : null}
              </Grid>
              <Grid item xs={6} className={classes.headerRight}>
                <Button color="primary" onClick={logout}>Logout</Button>
              </Grid>
            </Grid>
          </Container>
        ) : null}
      </Grid>

      <Grid item container className={classes.main}>
        <Container maxWidth="lg" component="main" className={narrow ? classes.mainInner : undefined}>
          <Paper elevation={3} className={classes.mainInner}>
            <Grid
              container
              alignItems='stretch'
              className={classes.mainInner}
              direction={rows ? 'column' : 'row'}
              justifyContent='space-between'
            >
              <Grid item xs={!rows ? 12 : undefined} lg={!rows ? 5 : undefined}>
                <Box my={4} mx={4}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    <Link to='/'>
                      <img src={logo} alt="Sendemeldung.de" className={classes.logo}/>
                    </Link>
                  </Typography>
                </Box>
                <Box my={4} mx={4}>
                  {children}
                </Box>
              </Grid>
              <Grid item xs={!rows ? 12 : undefined} lg={!rows ? 7 : undefined} className={classes.pictureColumn}>
                {pictureBox}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Grid>

      <Grid item className={classes.footer}>
        <footer>
          <Container maxWidth="sm">
            <Typography variant="body2" color="textSecondary" className={classes.footerLine}>
              <Link to="/" color="primary">
                Home
              </Link>
              {' / '}
              {loggedIn ? (
                <>
                  {hasOrganizations && (hasCredentials || hasJustSetUpCredentials) ? (
                    <>
                      <Link to="/dashboard/" color="primary">
                        Dashboard
                      </Link>
                      {' / '}
                    </>
                  ) : !hideGotoDashboard && hasProfile ? (
                    <>
                      <Link to="/teilnahme/dashboard/" color="primary">
                        Ihre Teilnahme
                      </Link>
                      {' / '}
                    </>
                  ) : null}
                </>
              ) : null}
              <Link to="/datenschutz/" color="primary">
                Datenschutz
              </Link>
              {' / '}
              <Link to="/impressum/" color="primary">
                Impressum
              </Link>
            </Typography>
          </Container>
        </footer>
      </Grid>
    </Grid>
  );
}

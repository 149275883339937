import {formatDuration, formatISO} from 'date-fns';
import _ from 'lodash';
import {Duration} from "luxon";
import deLocale from "date-fns/locale/de";

export function parseDate(dateStr) {
  if (!dateStr) {
    return undefined;
  }
  const parts = dateStr.split('-');
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function isValidDate(date) {
  date = new Date(date);
  return (date !== "Invalid Date") && !isNaN(date);
}

export function formatDate(date) {
  if (!date) {
    return date;
  }
  return formatISO(date, {representation: 'date'});
}

export function formatDateTime(dt) {
  if (!dt) {
    return dt;
  }
  return formatISO(dt, {representation: 'complete'});
}

export function formatReadableDuration(duration) {
  if (!_.isObject(duration)) {
    duration = Duration.fromObject({
      seconds: duration?.toFixed(0),
      minutes: 0,
      hours: 0,
      days: 0,
    }).normalize();
  }

  let format = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

  if (duration.as('seconds') >= 86400) {
    format = ['days', 'hours'];
  } else if (duration.as('seconds') >= 3600) {
    format = ['hours', 'minutes'];
  } else if (duration.as('seconds') >= 60) {
    format = ['minutes', 'seconds'];
  }

  return formatDuration(
    duration,
    {
      locale: deLocale,
      delimiter: ", ",
      format,
    },
  );
}

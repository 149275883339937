import {
  Button,
  Chip,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem, Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import {Album, Dns, Email, KeyboardArrowDown, MonetizationOn, PlaylistPlay, Radio} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedOrganization, getSelectedOrganizationId, selectOrganization} from "src/features/dashboard";
import makeStyles from '@mui/styles/makeStyles';
import {navigate} from "gatsby";
import {selectOrganizations} from "src/features/orm/selectors";
import {getOrganizationGetter} from "src/features/entity";
import {useEntityObserver} from "src/features/entity/entity-hooks";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   marginLeft: -theme.spacing(2),
  // },
  buttonTitle: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
}));

function OrganizationMenuItemContent({id, onSelectPath}) {
  const data = useSelector(getOrganizationGetter)(id);
  const {
    display_name,
    company_name,
    customer_id,
    is_gemagvl_approved: isApproved,
    has_published_reports: hasPublished,
    requires_test: requiresTest,
    unread_inbox_documents_count: unreadInboxDocumentsCount,
    unread_sent_documents_count: unreadSentDocumentsCount,
    draft_documents_count: draftDocumentsCount,
    outstanding_reports_count: outstandingReportsCount,
    org_music_works_count: musicWorksCount,
    databases,
    active_stations,
    type,
  } = data;

  const unreadDocumentsCount = unreadInboxDocumentsCount + unreadSentDocumentsCount + draftDocumentsCount;

  useEntityObserver({type: 'organization', id});

  let badge = null;

  const makePathOnClickHandler = (path) => (evt) => {onSelectPath(path); evt.stopPropagation();}

  if (!isApproved) {
    if (hasPublished) {
      badge = (
        <>
          {' '}
          <Chip size="small" label="warte auf Freigabe" style={{cursor: 'pointer'}}/>
        </>
      );
    } else if (requiresTest) {
      badge = (
        <>
          {' '}
          <Chip size="small" label="Test erforderlich" color="primary" style={{cursor: 'pointer'}}/>
        </>
      );
    }
  } else if (unreadDocumentsCount > 0 || musicWorksCount > 0 || outstandingReportsCount > 0) {
      badge = (
        <>
          {musicWorksCount > 0 ? (
            <>
              {' '}
              <Tooltip title="Musikproduktionen">
                <Chip size="small" icon={<Album/>} label={musicWorksCount} color="default" style={{cursor: 'pointer'}} onClick={makePathOnClickHandler(`/dashboard/database/${databases?.[0]}/`)}/>
              </Tooltip>
            </>
          ) : null}
          {outstandingReportsCount > 0 ? (
            <>
              {' '}
              <Tooltip title="offene Sendemeldungen">
                <Chip size="small" icon={<PlaylistPlay/>} label={outstandingReportsCount} color="primary" style={{cursor: 'pointer'}} onClick={makePathOnClickHandler('/dashboard/reports/')}/>
              </Tooltip>
            </>
          ) : null}
          {unreadDocumentsCount > 0 ? (
            <>
              {' '}
              <Tooltip title="ungelesene Nachrichten">
                <Chip size="small" icon={<Email/>} label={unreadDocumentsCount} color="primary" style={{cursor: 'pointer'}} onClick={makePathOnClickHandler('/dashboard/documents/inbox/')}/>
              </Tooltip>
            </>
          ) : null}
        </>
      );
  }

  let station_badges = null;
  if (active_stations?.length > 0) {
    station_badges = (
      <>
        {active_stations.map(({id, gemagvl_sender_prg_id}, i) => (
          <React.Fragment key={id || i}>
            <Chip
              size="small"
              label={gemagvl_sender_prg_id}
              color="default"
              style={{cursor: 'pointer'}}
              onClick={makePathOnClickHandler(`/dashboard/reports/?filter=station_${id}%3Dinclude&ordering=-report_datum_bis`)}
            />
            {' '}
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      <ListItemIcon>
        {type === 'HF' ? (
          <Radio/>
        ) : type === 'VWG' ? (
          <MonetizationOn/>
        ) : type === 'PRV' ? (
          <Dns/>
        ) : null}
      </ListItemIcon>
      {display_name ? (
        <>
          <ListItemText
            primary={(
              <>
                {display_name}
                <span style={{visibility: 'hidden'}}>{badge}</span>
              </>
            )}
            secondary={(<>
              {company_name}<br/>
              {customer_id ? (<>#{customer_id}</>) : null}
              {station_badges ? (
                <>
                  {' '}
                  {station_badges}
                </>
              ) : null}
              <span style={{visibility: 'hidden'}}>{badge}</span>
            </>)}
          />
        </>
      ) : company_name ? (
        <ListItemText
          primary={(
            <>
              {company_name}
              <span style={{visibility: 'hidden'}}>{badge}</span>
            </>
          )}
          secondary={(<>
            {customer_id ? (<>#{customer_id}</>) : null}
            {station_badges ? (
              <>
                {' '}
                {station_badges}
              </>
            ) : null}
            <span style={{visibility: 'hidden'}}>{badge}</span>
          </>)}
        />
      ) : (
        id
      )}

      {badge ? (
        <ListItemSecondaryAction sx={{height: '100%', pt: 1}}>
          {badge}
        </ListItemSecondaryAction>
      ) : null}
    </>
  );
}

export default function OrganizationChoiceButton({title}) {
  const classes = useStyles();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleSelect = (organization, path) => {
    dispatch(selectOrganization({organization}));
    closeMenu();

    if (path) {
      navigate(path);
      return;
    }

    // Quick hack: If the path contains a UUID (identified by '-'), redirect to start page when changing the
    //   organization to prevent displaying objects from other organizations.
    if (organization !== selectedOrganizationId && window?.location?.pathname?.includes('-') && !window?.location?.pathname?.match(/^\/dashboard\/database\/[a-z0-9-]+\/?$/)) {
      navigate('/dashboard/');
    }
  };

  const selectedOrganization = useSelector(getSelectedOrganization);

  const organizations = useSelector(selectOrganizations);

  return (
    <>
      <Button
        aria-label="account of current user"
        aria-controls="organization-choice"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
        endIcon={<KeyboardArrowDown/>}
        className={classes.root}
        sx={{ml: -1}}
      >
        <Typography variant="h6" className={classes.buttonTitle}>
          {title}
        </Typography>
      </Button>
      <Menu
        id="organization-choice-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
      >
        {!open ? null : organizations.filter((organization) => organization?.databases).map((organization, i) => (
          <MenuItem
            key={organization?.id || i}
            selected={organization?.id === selectedOrganization?.id}
            onClick={() => handleSelect(organization?.id)}
          >
            <OrganizationMenuItemContent id={organization?.id} onSelectPath={(path) => handleSelect(organization?.id, path)}/>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

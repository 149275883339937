import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Collapse,
  DialogContent,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {SimpleYupFormDialog} from "src/packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {FEEDBACK_SCHEMA} from "src/features/dashboard/dashboard-validation";
import {YupField} from "src/packages/react-hook-form-mui-yup-helpers";
import makeStyles from '@mui/styles/makeStyles';
import {BugReport, ExpandMore} from "@mui/icons-material";
import {useEntityApi} from "src/features/entity/entity-hooks";
import {USER_FEEDBACK} from "src/api/api-schemas";
import {useSelector} from "react-redux";
import {getSelectedOrganizationId, getSessionContact} from "src/features/dashboard";
import {getKnownBugGetter} from "src/features/entity";
import {Pagination} from "src/components/paginator";
import {useListing} from "src/features/ui/listing/listing-hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Feedback(
  {
    open,
    onClose,
    ...props
  }
) {
  const {
    name,
    email
  } = useSelector(getSessionContact);
  const [feedback, setFeedback] = useState({
    name,
    email
  });

  const classes = useStyles();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const {
    filterProps,
    paginationProps,
    isLoading,
    noDataExists,
    renderIds: knownBugRenderIds,
  } = useListing({
    listingId: 'known_bugs',
    endpoint: '/api/sendemeldung/known_bugs/',
    entityType: 'known_bug',
  });

  const getKnownBug = useSelector(getKnownBugGetter);

  const feedbackApi = useEntityApi(USER_FEEDBACK);

  const saveFeedback = async (validatedData) => {
    await feedbackApi.post(
      `/api/sendemeldung/organizations/${selectedOrganizationId}/feedback/`,
      validatedData,
    );
  };

  return (
    <SimpleYupFormDialog
      id="feedback"
      title="Bug melden"
      open={!!open}
      data={feedback}
      onClose={onClose}
      submit={saveFeedback}
      schema={FEEDBACK_SCHEMA}
      saveCaption="Feedback absenden"
      cancelCaption="Schließen"
      maxWidth="xl"
      {...props}
    >
      <DialogContent>
        <Typography gutterBottom>
          Wir entwickeln Sendemeldung.de kontinuierlich weiter.
        </Typography>
        <Typography gutterBottom>
          Damit wir Ihre Anforderungen berücksichtigen können, sind wir auf Ihre Mithilfe angewiesen:
          Bitte teilen Sie uns mit, wenn etwas nicht bzw. nicht wie erwartet funktioniert oder wenn Sie sonstige
          Anregungen haben.
          Wir geben uns größte Mühe, jede Anregung &ndash; in Absprache mit der Steuerungsgruppe der Verbände
          &ndash; zu berücksichtigen.
        </Typography>

        <Collapse in={!noDataExists && knownBugRenderIds?.length > 0}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMore/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Bekannte Probleme</Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection: 'column'}}>
              <Typography>
                Damit Sie nichts melden müssen, das bereits bekannt ist, finden Sie nachfolgend eine Liste bekannter
                Anregungen und Bugs, an deren Umsetzung bzw. Behebung wir bereits arbeiten:
              </Typography>

              <Pagination {...paginationProps}/>

              {knownBugRenderIds?.map(getKnownBug)
                .map(({
                  id,
                  title,
                  description
                }, i) => (
                  <Box mt={2} key={id || i}>
                    <Alert severity="info" icon={<BugReport/>}>
                      <AlertTitle>{title}</AlertTitle>
                      {description}
                    </Alert>
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        </Collapse>

        <Paper>
          <Box p={2} mt={2}>
            <Typography variant="h6" className={classes.heading} gutterBottom>Neues Feedback</Typography>
            <Box mb={2}>
              <Alert variant="filled" severity="info">
                Haben Sie einen neuen Bug entdeckt oder sonstige Anregungen oder Hinweise an uns?
                Dann schreiben Sie uns gerne über das folgende Kontaktformular.
              </Alert>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <YupField name="name" variant="outlined"/>
              </Grid>
              <Grid item xs={12}>
                <YupField name="email" variant="outlined"/>
              </Grid>
              <Grid item xs={12}>
                <YupField name="message" variant="outlined"/>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
    </SimpleYupFormDialog>
  );
}

import {Divider, List, ListItemIcon, ListItemText} from "@mui/material";
import React from "react";
import {BugReport, HelpCenter, Timelapse} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import logo from "src/components/layout/sendemeldung_de.svg";
import Typography from "@mui/material/Typography";
import {Link} from "gatsby-theme-material-ui";
import HrefMenuListItem from "src/packages/gatsby-mui-helpers/HrefMenuListItem";
import {getSelectedOrganization, getVersion} from "src/features/dashboard";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  drawerFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  logo: {
    padding: theme.spacing(1, 3),
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export default function DrawerBottomMenu({
  selected,
  feedbackOpen,
  openFeedback
}) {
  const classes = useStyles();

  const version = useSelector(getVersion);

  const {
    known_bugs_count,
  } = useSelector(getSelectedOrganization);

  return (
    <>
      <List>
        <HrefMenuListItem button href={'/dashboard/help/'} active={selected === 'help'}>
          <ListItemIcon><HelpCenter/></ListItemIcon>
          <ListItemText primary="Hilfe"/>
        </HrefMenuListItem>
        {known_bugs_count > 0 ? (
          <HrefMenuListItem button href={'/dashboard/known_bugs/'} active={selected === 'known_bugs'}>
            <ListItemIcon><BugReport/></ListItemIcon>
            <ListItemText primary="Bekannte Probleme"/>
          </HrefMenuListItem>
        ) : null}
      </List>
      <Divider/>
      <List>
        <HrefMenuListItem button href={'/dashboard/timeline/'} active={selected === 'timeline'}>
          <ListItemIcon><Timelapse/></ListItemIcon>
          <ListItemText primary="Projektstatus"/>
        </HrefMenuListItem>
      </List>
      <Divider/>
      <div className={classes.drawerFooter}>
        <div>
          <img src={logo} alt="Sendemeldung.de" className={classes.logo}/><br/>
          <Typography paragraph align="center">
            <small>
              Produktivbetrieb<br/>
              {version ? (
                <Link to="/dashboard/changelog/" style={{textDecoration: 'none'}} color="inherit">
                  Version {version}
                </Link>
              ) : null}
            </small>
          </Typography>
        </div>
      </div>
    </>
  );
}

import {
  Box,
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@mui/material";
import React, {useEffect} from "react";
import {
  Album,
  Business,
  CallMade,
  CallReceived,
  CreditCard,
  Dashboard as DashboardIcon,
  Drafts,
  Email,
  ExpandMore,
  ImportExport,
  Notifications,
  People,
  PlaylistPlay,
  Publish,
  Radio,
  Search,
  Security,
  Settings,
  Storage,
  CalendarMonth,
  Folder,
} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import {useSelector} from "react-redux";
import {
  getSelectedOrganization,
  getSelectedOrganizationId,
} from "src/features/dashboard";
import HrefMenuListItem from "src/packages/gatsby-mui-helpers/HrefMenuListItem";
import HrefComponent from "src/packages/gatsby-mui-helpers/HrefComponent";
import OnlyIfPermissions from "src/features/dashboard/OnlyIfPermissions";
import {useDatabase} from "src/features/entity/entity-hooks";
import {parseDate} from "src/packages/date-utils";

const useStyles = makeStyles((theme) => ({
  active: {
    '& .MuiListItemText-root, & .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

function DatabaseMenuItem({
  id,
  selected
}) {
  const {
    owner,
    name,
    org_music_works_count,
  } = useDatabase({id});
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  return (
    <HrefMenuListItem button sx={{pl: 4}} href={`/dashboard/database/${id}/`} active={selected === `database/${id}`}>
      <ListItemIcon><Album/></ListItemIcon>
      <ListItemText
        primary={(
          owner === selectedOrganizationId ? (
            "Eigene Musik"
          ) : (
            "Fremddatenbank"
          )
        )}
        secondary={(
          owner !== selectedOrganizationId ? (
            <Box component="span" sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '75%'
            }}>{name}</Box>
          ) : null
        )}
      />
      <HrefComponent component={ListItemSecondaryAction} href={`/dashboard/database/${id}/`}>
        {org_music_works_count ? (
          <Chip size="small" label={org_music_works_count} align="right" style={{cursor: 'pointer'}}/>
        ) : null}
      </HrefComponent>
    </HrefMenuListItem>
  );
}

export function DrawerMainMenuItem({sx, activeDrawerMainMenuItem, active, children, ...props}) {
  return (
    (active && activeDrawerMainMenuItem) ? activeDrawerMainMenuItem : (
      <HrefMenuListItem button sx={sx} active={active} {...props}>
        {children}
      </HrefMenuListItem>
    )
  );
}

export function MenuListItem({
  active,
  children,
  className,
  ...props
}) {
  const classes = useStyles();

  return (
    <ListItem
      {...props}
      className={active ? clsx(classes.active, className) : className}
    >
      {children}
    </ListItem>
  );
}

export default function DrawerMainMenu({
  selected,
  ...props
}) {

  const {
    org_music_works_count,
    outstanding_reports_count,
    request_user_unread_notifications_count,
    databases,
    type,
    supports_music,
    supports_reports,
    draft_documents_count,
    unread_inbox_documents_count,
    unread_sent_documents_count,
    recent_report_date,
  } = useSelector(getSelectedOrganization);

  const recentReportDate = parseDate(recent_report_date);

  const documentsExpanded = (selected === 'documents' || selected?.startsWith('documents/'));
  const messagesCount = unread_inbox_documents_count + draft_documents_count + unread_sent_documents_count;

  const settingsExpanded = (
    selected === 'organization' ||
    selected === 'programmes' ||
    selected === 'people' ||
    selected === 'security' ||
    selected === 'gemagvl4' ||
    selected === 'billing'
  );

  return (
    <>
      <List>
        <HrefMenuListItem
          button
          href={'/dashboard/'}
          active={selected === 'dashboard'}
        >
          <ListItemIcon><DashboardIcon/></ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </HrefMenuListItem>
        {type === 'HF' ? (
          <OnlyIfPermissions perm_read_reports>
            <HrefMenuListItem
              button
              href={'/dashboard/import/'}
              active={selected === 'import'}
            >
              <ListItemIcon><Publish/></ListItemIcon>
              <ListItemText primary="Hochgeladene Dateien"/>
            </HrefMenuListItem>
          </OnlyIfPermissions>
        ) : null}
        <HrefMenuListItem
          button
          href={'/dashboard/notifications/'}
          active={selected === 'notifications'}
        >
          <ListItemIcon><Notifications/></ListItemIcon>
          <ListItemText primary="Benachrichtigungen"/>
          <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/notifications/'}>
            {request_user_unread_notifications_count ? (
              <Chip size="small" color="primary" label={request_user_unread_notifications_count} align="right"
                    style={{cursor: 'pointer'}}/>
            ) : null}
          </HrefComponent>
        </HrefMenuListItem>
      </List>
      {supports_music || supports_reports ? (
        <>
          <Divider/>
          <List>
            <OnlyIfPermissions perm_read_reports>
              <HrefMenuListItem button href={'/dashboard/reports/'} active={false}>
                <ListItemIcon><PlaylistPlay/></ListItemIcon>
                <ListItemText primary="Sendemeldungen"/>
                {selected === 'reports' || selected === 'calendar' ? <ExpandMore/> : (
                  <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/reports/'}>
                    {outstanding_reports_count ? (
                      <Chip size="small" color="primary" label={outstanding_reports_count} align="right"
                            style={{cursor: 'pointer'}}/>
                    ) : null}
                  </HrefComponent>
                )}
              </HrefMenuListItem>
            </OnlyIfPermissions>
            <Collapse in={selected === 'reports' || selected === 'calendar'} timeout="auto" unmountOnExit>
              <OnlyIfPermissions perm_read_reports>
                <List component="div" disablePadding>
                  <DrawerMainMenuItem sx={{pl: 4}} href={'/dashboard/reports/'} active={selected === 'reports' || selected === 'calendar'} {...props}>
                    <ListItemIcon><Folder/></ListItemIcon>
                    <ListItemText primary="Alle Lieferungen"/>
                    <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/reports/'}>
                      {outstanding_reports_count ? (
                        <Chip size="small" color="primary" label={outstanding_reports_count} align="right"
                              style={{cursor: 'pointer'}}/>
                      ) : null}
                    </HrefComponent>
                  </DrawerMainMenuItem>
                </List>
              </OnlyIfPermissions>
            </Collapse>
            <OnlyIfPermissions perm_read_music>
              <HrefMenuListItem button href={`/dashboard/database/${databases?.[0]}/`}
                                active={selected === 'database/undefined'}>
                <ListItemIcon><Storage/></ListItemIcon>
                <ListItemText primary="Musikarchiv"/>
                {selected === 'refdata' || selected?.startsWith('database/') ? <ExpandMore/> : (
                  <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/database/'}>
                    {org_music_works_count ? (
                      <Chip size="small" label={org_music_works_count} align="right" style={{cursor: 'pointer'}}/>
                    ) : null}
                  </HrefComponent>
                )}
              </HrefMenuListItem>
            </OnlyIfPermissions>
            <Collapse in={selected === 'refdata' || selected?.startsWith('database/')} timeout="auto" unmountOnExit>
              <OnlyIfPermissions perm_read_music>
                <List component="div" disablePadding>
                  {databases?.map((id) => (
                    <DatabaseMenuItem key={id} id={id} selected={selected}/>
                  ))}
                  <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/refdata/'} active={selected === 'refdata'}>
                    <ListItemIcon><Search/></ListItemIcon>
                    <ListItemText primary="Referenzdaten"/>
                  </HrefMenuListItem>
                </List>
              </OnlyIfPermissions>
            </Collapse>
          </List>
        </>
      ) : null}
      <OnlyIfPermissions perm_read_documents>
        <Divider/>
        <List>
          <HrefMenuListItem button href={'/dashboard/documents/inbox/'} active={selected === 'documents'}>
            <ListItemIcon><Email/></ListItemIcon>
            <ListItemText primary="Kontakt"/>
            {documentsExpanded ? <ExpandMore/> : null}
          </HrefMenuListItem>

          <Collapse in={!documentsExpanded} timeout="auto" unmountOnExit>
            <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/documents/inbox/'}>
              {messagesCount ? (
                <Chip size="small" label={messagesCount} color="primary" align="right" style={{cursor: 'pointer'}}/>
              ) : null}
            </HrefComponent>
          </Collapse>
          <Collapse in={documentsExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/documents/inbox/'}
                                active={selected === 'documents/inbox'}>
                <ListItemIcon><CallReceived/></ListItemIcon>
                <ListItemText primary="Posteingang"/>
                <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/documents/inbox/'}>
                  {unread_inbox_documents_count ? (
                    <Chip size="small" color="primary" label={unread_inbox_documents_count} align="right"
                          style={{cursor: 'pointer'}}/>
                  ) : null}
                </HrefComponent>
              </HrefMenuListItem>
              <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/documents/drafts/'}
                                active={selected === 'documents/drafts'}>
                <ListItemIcon><Drafts/></ListItemIcon>
                <ListItemText primary="Entwürfe"/>
                <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/documents/drafts/'}>
                  {draft_documents_count ? (
                    <Chip size="small" color="primary" label={draft_documents_count} align="right"
                          style={{cursor: 'pointer'}}/>
                  ) : null}
                </HrefComponent>
              </HrefMenuListItem>
              <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/documents/sent/'}
                                active={selected === 'documents/sent'}>
                <ListItemIcon><CallMade/></ListItemIcon>
                <ListItemText primary="Gesendet"/>
                <HrefComponent component={ListItemSecondaryAction} href={'/dashboard/documents/sent/'}>
                  {unread_sent_documents_count ? (
                    <Chip size="small" color="primary" label={unread_sent_documents_count} align="right"
                          style={{cursor: 'pointer'}}/>
                  ) : null}
                </HrefComponent>
              </HrefMenuListItem>
            </List>
          </Collapse>
        </List>
      </OnlyIfPermissions>
      <Divider/>
      <List>
        <HrefMenuListItem button href={'/dashboard/organization/'}>
          <ListItemIcon><Settings/></ListItemIcon>
          <ListItemText primary="Einstellungen"/>
          {settingsExpanded ? <ExpandMore/> : null}
        </HrefMenuListItem>

        <Collapse in={settingsExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <OnlyIfPermissions perm_read_details>
              <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/organization/'}
                                active={selected === 'organization'}>
                <ListItemIcon>
                  <Business/>
                </ListItemIcon>
                <ListItemText primary="Stammdaten"/>
              </HrefMenuListItem>
            </OnlyIfPermissions>
            {type === 'HF' ? (
              <OnlyIfPermissions perm_read_stations>
                <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/programmes/'}
                                  active={selected === 'programmes'}>
                  <ListItemIcon>
                    <Radio/>
                  </ListItemIcon>
                  <ListItemText primary="Programme"/>
                </HrefMenuListItem>
              </OnlyIfPermissions>
            ) : null}
            <OnlyIfPermissions perm_read_people>
              <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/people/'}
                                active={selected === 'people'}>
                <ListItemIcon>
                  <People/>
                </ListItemIcon>
                <ListItemText primary="Personen"/>
              </HrefMenuListItem>
            </OnlyIfPermissions>
            <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/security/'}
                              active={selected === 'security'}>
              <ListItemIcon>
                <Security/>
              </ListItemIcon>
              <ListItemText primary="Sicherheit"/>
            </HrefMenuListItem>
            {type === 'HF' ? (
              <OnlyIfPermissions perm_read_details perm_write_reports>
                <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/gemagvl4/'}
                                  active={selected === 'gemagvl4'}>
                  <ListItemIcon>
                    <ImportExport/>
                  </ListItemIcon>
                  <ListItemText primary="GEMAGVL4-Import"/>
                </HrefMenuListItem>
              </OnlyIfPermissions>
            ) : null}
            {type === 'HF' ? (
              <HrefMenuListItem button sx={{pl: 4}} href={'/dashboard/billing/'}
                                active={selected === 'billing'}>
                <ListItemIcon><CreditCard/></ListItemIcon>
                <ListItemText primary="Abrechnung"/>
              </HrefMenuListItem>
            ) : null}
          </List>
        </Collapse>
      </List>
      <Divider sx={{mb: '-1px'}}/>
    </>
  );
}

import {channel} from 'redux-saga';
import {actionChannel, all, call, delay, fork, put, race, select, spawn, take} from 'redux-saga/effects';
import {deleteEntity, registerEntityObserver, unregisterEntityObserver} from "src/features/entity/entity-slice";
import {getSelectedOrganization, getSelectedOrganizationId, getVersion} from "src/features/dashboard";
import {ENTITY_LIST_URLS, ENTITY_TYPES} from "src/api/api-schemas";
import {getEntityApi} from "src/features/entity/entity-hooks";
import {isDocumentVisible, setVisible} from "src/features/ui/visibility-tracker";

const ENTITY_TYPE_SAGA_ACTION_TYPES = [
  registerEntityObserver.type,
  unregisterEntityObserver.type,
];

const createEntityTypeSaga = ({type}) => (function*(chan) {
  const registeredIds = {};
  let registeredIdsCount = 0;
  let justAdded = false;

  let warned = false;

  let queuedActions = [];

  const dispatch = function (action) {
    queuedActions.push(action);
  }

  let lastRefreshTime = null;

  while (true) {
    while (queuedActions.length > 0) {
      yield put(queuedActions.pop());
    }

    const {ui_polling_interval_active, ui_polling_interval_inactive} = yield select(getSelectedOrganization);

    const {action, shouldUpdate, setVisible: changedVisibility} = yield race({
      action: take(chan),
      ...(registeredIdsCount > 0 ? {
        shouldUpdate: delay(justAdded ? 100 : (ui_polling_interval_active || 2000)),
        setVisible: take([setVisible]),
      } : {}),
    });

    if (action) {
      const {id} = action.payload;

      if (action.type === registerEntityObserver.type) {
        if (registeredIds[id]) {
          registeredIds[id]++;
        } else {
          registeredIds[id] = 1;
          registeredIdsCount++;
        }
        justAdded = true;
      } else if (action.type === unregisterEntityObserver.type) {
        registeredIds[id]--;
        if (!registeredIds[id]) {
          delete registeredIds[id];
          registeredIdsCount--;
        }
      }
    }

    if (shouldUpdate || changedVisibility) {
      const currentTime = new Date();
      if (lastRefreshTime && !(yield select(isDocumentVisible))) {
        if (currentTime - lastRefreshTime < (ui_polling_interval_inactive || 60000)) {
          // console.log(`throttle update of ${type} since page is not visible`);
          continue;
        }
      }
      lastRefreshTime = currentTime;

      justAdded = false;

      const ids = Object.keys(registeredIds);

      const schema = ENTITY_TYPES[type];
      const urlTemplate = ENTITY_LIST_URLS[type];

      if (schema && urlTemplate) {
        // Perform updates in chunks to prevent URLs from becoming too long and to limit per-query server load.
        const chunkSize = 30;
        for (let i = 0, j = ids.length; i < j; i += chunkSize) {
          const idsChunk = ids.slice(i, i + chunkSize);

          const id__in = idsChunk.join(',');
          let url = urlTemplate;
          if (urlTemplate.indexOf('{organizationId}') !== -1) {
            const organizationId = yield select(getSelectedOrganizationId);
            if (!organizationId) {
              continue;
            }
            url = url.replace('{organizationId}', organizationId);
          }

          url += `?id__in=${id__in}`;

          // console.log(`do update ${type}: ${ids}`);
          const version = yield select(getVersion);
          const entityApi = getEntityApi([schema], dispatch, version);
          try {
            {
              const {normalizedData} = yield call(entityApi.get, url);
              // yield put(setEntities(normalizedData));

              const resultIds = normalizedData?.result?.map(x => `${x}`);  // ids need to be of type string

              for (const id of idsChunk) {
                if (!resultIds.includes(id)) {
                  console.log(`${type}/${id} went missing`);
                  // FIXME: Normalize entity type names.

                  let pluralType = type + `s`;
                  if (type.endsWith('ung')) {
                    pluralType = type + 'en';
                  }
                  yield put(deleteEntity(pluralType, id));
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      } else if (!warned) {
        console.log(`should update ${type}: ${ids}`);
        warned = true;
      }
    }
  }
})

function* entityTypeSagasManager() {
  // We do not want to miss any relevant event.
  const entityTypeActionsChan = yield actionChannel(ENTITY_TYPE_SAGA_ACTION_TYPES);

  const entityTypeSagas = {};
  while (true) {
    const action = yield take(entityTypeActionsChan);
    const {type} = action.payload;

    let entityTypeSaga = entityTypeSagas[type];

    if (entityTypeSaga && !entityTypeSaga.task.isRunning()) {
      delete entityTypeSagas[type];
      entityTypeSaga = undefined;
    }

    if (!entityTypeSaga) {
      const chan = yield call(channel);
      entityTypeSagas[type] = entityTypeSaga = {
        chan,
        task: yield fork(createEntityTypeSaga({type}), chan),
      }
    }

    yield put(entityTypeSaga.chan, action);
  }
}

export default function* rootSaga() {
  yield spawn(entityTypeSagasManager);
  yield all([]);
}

import React from "react";
import {useYupContext} from "src/packages/react-hook-form-mui-yup-helpers/yup-form-provider";
import {
  FormCheckboxField,
  FormChoicesField,
  FormDateField,
  FormDateTimeField,
  FormTextField,
  FormTextFieldSuggestions,
} from "src/packages/react-hook-form-mui-form-fields";
import {reach} from "yup";
import {useFormContext} from "react-hook-form";

export default function YupField({name, Component, ...props}) {
  const schema = useYupContext();
  const {setValue} = useFormContext();

  const fieldSchema = reach(schema, name);
  const {label, type} = fieldSchema.describe();
  const meta = fieldSchema.meta();

  const autoComplete = meta?.autoComplete;
  const options = meta?.options;
  const optionKeyBindings = meta?.optionKeyBindings;
  const rows = meta?.rows;
  const helperText = meta?.helperText;

  let FormFieldComponent;
  if (Component) {
    FormFieldComponent = Component;
  } else if (type === "boolean") {
    FormFieldComponent = FormCheckboxField;
  } else if (options) {
    FormFieldComponent = FormChoicesField;
  } else if (meta?.datetime) {
    FormFieldComponent = FormDateTimeField;
  } else if (meta?.date) {
    FormFieldComponent = FormDateField;
  } else if (meta?.suggestions && props.suggestions) {
    FormFieldComponent = FormTextFieldSuggestions;
  } else {
    FormFieldComponent = FormTextField;
  }

  let onKeyPress;
  if (optionKeyBindings) {
    onKeyPress = (event) => {
      if (optionKeyBindings[event.key] !== undefined) {
        setValue(name, optionKeyBindings[event.key]);
      }
    };
  }

  let extraAttrs = {};

  if (rows) {
    extraAttrs['multiline'] = true;
    extraAttrs['rows'] = rows;
  }

  if (meta?.password) {
    extraAttrs['type'] = 'password';
  }

  return (
    <FormFieldComponent
      name={name}
      label={label}
      autoComplete={autoComplete}
      items={options}
      onKeyPress={onKeyPress}
      helperText={helperText}
      {...extraAttrs}
      {...props}
    />
  );
}

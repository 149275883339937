import Pagination from './pagination-view';
import {getListing, updateListing} from "src/features/ui/listing";
import {connect} from "react-redux";

export default connect(
  (state, {listingId}) => {
    const {
      currentPage,
      count,
      pageSize,
      error
    } = getListing(state)(listingId);
    const totalPages = Math.ceil(count / pageSize) || 0;

    return {
      currentPage,
      totalPages,
      pageSize,
      count,
      error,
    };
  },
  (dispatch, {
    listingId,
    setPage,
    onSetPage
  }) => {
    return {
      setPage: (page) => {
        let result;
        if (setPage) {
          result = setPage(page);
        } else {
          result = dispatch(updateListing({
            id: listingId,
            currentPage: page
          }));
        }
        if (onSetPage) {
          onSetPage(page);
        }
        return result;
      },
    };
  },
)(Pagination);

import {createSelector, createSlice} from "@reduxjs/toolkit";
import {logout} from "src/features/session";

const initialState = {};

const participationSlice = createSlice({
  name: 'participation',
  initialState,
  reducers: {
    setProfile(state, action) {
      const {profile} = action.payload;
      state.profile = profile;
    },
    performProfileUpdate() {
    },
    updateProfile(state, action) {
      const profile = action.payload;
      state.profile = {
        ...state.profile,
        ...profile,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state, action) => {
        delete state.profile;
      });
  },
});

export const {
  setProfile,
  performProfileUpdate,
  updateProfile,
} = participationSlice.actions;

const reducers = {
  participation: participationSlice.reducer,
};
export default reducers;

const getParticipationState = (state) => state.participation;

export const getProfile = createSelector(
  getParticipationState,
  (participationState) => participationState.profile || {},
);

export const hasProfileData = createSelector(
  getParticipationState,
  (participationState) => participationState.profile?.customer_id !== undefined,
);

import {all, call, delay, put, select, take} from 'redux-saga/effects';
import {
  getSessionData,
  isLoggedIn,
  login,
  login2fa,
  login2faFailed,
  loginFailed,
  loginRequires2fa,
  loginReset,
  loginSuccess,
  logout,
  logoutSuccess
} from './session-slice';
import axios from 'axios';
import _ from 'lodash';

function* loginSaga() {
  // Wait for login.
  let failedRecently = false;
  while (true) {
    const action = yield take([login]);

    // Process login.
    const {username, password, accessToken} = action.payload;

    const loginEndpoint = accessToken ? '/api/teilnahme/auth/login/' : '/api/sendemeldung/auth/login/';

    try {
      let response = yield call(axios.post, loginEndpoint, (accessToken ? {
        username: '$welcome_access_token',
        password: accessToken,
      } : {
        username,
        password,
      }));
      if (!response.data) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error("submit failed");
      }

      // Process 2FA.
      const challenge = response.data?.['2fa_challenge'];
      let reset = false;
      if (challenge) {
        yield put(loginRequires2fa({challenge, username}));

        while (true) {
          const action = yield take([login2fa, loginReset]);

          if (action.type === loginReset.type) {
            reset = true;
            break;
          }

          const {challenge_device_id, otp_token} = action.payload;

          try {
            response = yield call(axios.post, loginEndpoint, {username, password, challenge_device_id, otp_token});
            if (!response.data) {
              // noinspection ExceptionCaughtLocallyJS
              throw new Error("submit failed");
            }

            break;
          } catch (e) {
            yield delay(500);  // Minimal delay for the user's convenience.

            let errors = e?.response?.data;
            if (!_.isObject(errors)) {
              errors = {non_field_errors: "Ein unerwarteter Fehler ist aufgetreten."};
            }
            yield put(login2faFailed({errors}));
          }
        }
      }

      if (reset) {
        continue;
      }

      yield put(loginSuccess(response.data));
      failedRecently = false;
      break;
    } catch (e) {
      if (failedRecently) {
        yield delay(500);  // Minimal delay for the user's convenience.
      }

      let errors = e?.response?.data;
      if (!_.isObject(errors)) {
        errors = {non_field_errors: "Ein unerwarteter Fehler ist aufgetreten."};
      }
      if (errors.non_field_errors && errors.non_field_errors[0] === 'Die angegebenen Zugangsdaten stimmen nicht.' && accessToken) {
        errors.accessToken = '';
        errors.non_field_errors[0] = "Der angegebene Zugangscode ist ungültig.";
      }
      yield put(loginFailed({errors}));
      failedRecently = true;
    }
  }
}

function* logoutSaga() {
  // Wait for logout.
  yield take([logout]);

  // Process logout.
  try {
    yield call(axios.post, '/api/auth/logout/');
  } catch (e) {
    console.error(e);
  } finally {
    axios.defaults.headers.common = {}
    yield put(logoutSuccess());
  }
}

function* loginLogoutSaga() {
  while (true) {
    if (!(yield select(isLoggedIn))) {
      yield call(loginSaga);
    }
    yield call(logoutSaga);
  }
}

function* configureAxios() {
  while (true) {
    const {token} = yield select(getSessionData);
    if (token) {
      axios.defaults.headers.common = {'Authorization': `Token ${token}`}
    }
    yield take([loginSuccess]);
  }
}

export default function* rootSaga() {
  yield all([
    call(loginLogoutSaga),
    call(configureAxios),
  ]);
}

import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import {persistReducer, persistStore} from "redux-persist";
import {PERSIST} from 'redux-persist/lib/constants';
import * as localforage from "localforage";
import featureReducers from "../features";
import {createUnloadBlockerStoreEnhancer} from "src/features/ui/unload-blocker";
import {createVisibilityTrackerStoreEnhancer} from "src/features/ui/visibility-tracker";

const devMode = (process.env.NODE_ENV !== 'production');

const persistSessionConfig = {
  key: 'session',
  storage: localforage,
  whitelist: ['data'],
};

const persistUiConfig = {
  key: 'ui',
  storage: localforage,
  whitelist: ['preferences'],
};

const persistDashboardConfig = {
  key: 'dashboard',
  storage: localforage,
  whitelist: ['selectedOrganization', 'mainMenuOpen', 'backendVersion'],
};

const unloadBlockerEnhancer = createUnloadBlockerStoreEnhancer();
const visibilityTrackerEnhancer = createVisibilityTrackerStoreEnhancer();

const createStore = (sagaMiddleware) => {
  const {session, dashboard, ui, ...reducers} = featureReducers;

  const store = configureStore({
    reducer: combineReducers({
      session: persistReducer(persistSessionConfig, session),
      dashboard: persistReducer(persistDashboardConfig, dashboard),
      ui: persistReducer(persistUiConfig, ui),
      ...reducers,
    }),
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredActions: ['dashboard/uploadFile', PERSIST],
        },
      }),
      sagaMiddleware,
    ],
    enhancers: (middlewareEnhancers) => [
      ...middlewareEnhancers,
      unloadBlockerEnhancer,
      visibilityTrackerEnhancer,
    ],
    devTools: devMode,
  });

  const persistor = persistStore(store);

  return {store, persistor};
};

export default createStore;

import {ORM} from 'redux-orm';
import {
  Contact,
  ErschieneneTonaufnahme,
  GEMAGVL4Ausstrahlung,
  GEMAGVL4Lieferung, GEMAGVLXMLAusstrahlung, GEMAGVLXMLLieferung, KnownBug, MusikPerson, MusikProduktionID,
  Organization, OrganizationContactRole,
  OrgMusicWork, OrgMusicWorkLog, OrgMusicWorkLogs,
  GEMAGVL4Station, Station,
  UploadedFile, UserFeedback, Document, TodoTask, GVLProduct, GEMAWork, Notification,
  Database, DatabaseRight, LookupTable, GEMAGVLXMLLieferungStats,
} from './models';

const orm = new ORM({
  stateSelector: state => state.orm,
});
orm.register(
  Organization,
  Contact,
  OrganizationContactRole,
  Station,
  GEMAGVL4Station,
  UploadedFile,
  GEMAGVL4Lieferung,
  GEMAGVL4Ausstrahlung,
  ErschieneneTonaufnahme,
  OrgMusicWork,
  OrgMusicWorkLog,
  OrgMusicWorkLogs,
  MusikPerson,
  MusikProduktionID,
  GEMAGVLXMLLieferung,
  GEMAGVLXMLLieferungStats,
  GEMAGVLXMLAusstrahlung,
  UserFeedback,
  KnownBug,
  Document,
  Notification,
  TodoTask,
  GVLProduct,
  GEMAWork,
  Database,
  DatabaseRight,
  LookupTable,
);

export default orm;

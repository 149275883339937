import {Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import React from "react";
import {AccountCircle, ExitToApp, Person} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "src/features/session";
import {navigate} from "gatsby";
import {hasProfileData} from "src/features/participation";
import {getSelectedOrganization, getSessionContact} from "src/features/dashboard";
import {useEntityObserver} from "src/features/entity/entity-hooks";
import {getContactRoleGetter} from "src/features/entity";

export default function ProfileButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileOpen = Boolean(anchorEl);

  const hasProfile = useSelector(hasProfileData);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    closeMenu();
  };

  // Ensure that information about the session user is available (TODO: needs refactoring).
  const {request_user_contact_role} = useSelector(getSelectedOrganization);
  useEntityObserver({type: 'contact_role', id: request_user_contact_role});
  const {contact} = useSelector(getContactRoleGetter)(request_user_contact_role);
  useEntityObserver({type: 'contact', id: contact});

  const {id, organization, username, email} = useSelector(getSessionContact);

  return <>
    {username ? (
      <Button
        startIcon={<AccountCircle/>}
        aria-label="account of current user"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
      >
        {username}
      </Button>
    ) : (
      <IconButton
        aria-label="account of current user"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
        size="large"
      >
        <AccountCircle/>
      </IconButton>
    )}
    <Menu
      id="profile-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={profileOpen}
      onClose={closeMenu}
    >
      {/*<MenuItem onClick={closeMenu}>Mein Konto</MenuItem>*/}
      {hasProfile ? (
        <MenuItem onClick={
          typeof window !== "undefined" ? (
            () => window.location.href = '/teilnahme/dashboard'
          ) : (
            () => navigate('/teilnahme/dashboard')
          )}>
          <ListItemIcon>
            <Person fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            zur Teilnahme-Verwaltung
          </ListItemText>
        </MenuItem>
      ) : null}
      {hasProfile ? (
        <Divider/>
      ) : null}
      {username ? (
        <MenuItem
          onClick={() => {
            navigate('/dashboard/security');
            closeMenu();
          }}
        >
          <ListItemIcon>
            <Person fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            Mein Konto
          </ListItemText>
        </MenuItem>
      ) : null}
      {username ? (
        <Divider/>
      ) : null}
      <MenuItem
        onClick={() => {
          handleLogout();
          closeMenu();
        }}
      >
        <ListItemIcon>
          <ExitToApp fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          Logout
        </ListItemText>
      </MenuItem>
    </Menu>
  </>;
}
